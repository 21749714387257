<template>
  <div class="mb-4">
    <UiKitFormField :label="t('policy_file.label')">
      <UiKitUploadAny
        v-model="policyFile"
        class="mb-4"
        @error="onErrorUpload"
      />
    </UiKitFormField>
  </div>
</template>

<script>
import { NotifyMixin } from '@/helpers/mixins/NotifyMixin'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitUploadAny from '@/ui/UiKitUploadAny.vue'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemQuestions',

  components: {
    UiKitUploadAny,
    UiKitFormField,
  },

  mixins: [
    NotifyMixin,
  ],

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    policyFile: {
      get() {
        return this.localItem.policyFile
      },

      set(value) {
        this.localItem.policyFile = value

        this.emitChange()
      },
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.localItem = cloneDeep(this.modelValue)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', cloneDeep(this.localItem))
    },

    onErrorUpload(message) {
      this.notifyMethods().showError(`${this.t('load_file')}: ${message}`)
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.questions.${name}`)
    },
  },
})
</script>
