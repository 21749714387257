<template>
  <div class="d-flex align-items-center mt-2 mb-4">
    <div class="row">
      <div class="col-6">
        <UiKitFormField class="mb-4" :label="t('photo.label')">
          <UiKitUploadAny v-model="photo" />
        </UiKitFormField>

        <UiKitFormField class="mb-4" :label="t('rate.label')" required>
          <RatingStars v-model="rate" />
          <UiKitFormFieldErrors :errors="v$.modelValue.rate.$errors" />
        </UiKitFormField>
      </div>

      <div class="col-6">
        <UiKitFormField class="mb-4" :label="t('author_name.label')" required>
          <UiKitInput
            v-model="authorName"
            :placeholder="t('author_name.placeholder')"
          />
          <UiKitFormFieldErrors :errors="v$.modelValue.authorName.$errors" />
        </UiKitFormField>

        <UiKitFormField class="mb-4" :label="t('subtitle.label')" required>
          <UiKitInput
            v-model="subtitle"
            :placeholder="t('subtitle.placeholder')"
          />
          <UiKitFormFieldErrors :errors="v$.modelValue.subtitle.$errors" />
        </UiKitFormField>
      </div>

      <div class="col-12">
        <UiKitFormField class="mb-4" :label="t('text.label')" required>
          <UiKitWysiwyg v-model="text" :placeholder="t('text.placeholder')" />
          <UiKitFormFieldErrors :errors="v$.modelValue.text.$errors" />
        </UiKitFormField>

        <UiKitFormField :label="t('date.label')" required>
          <UiKitDatePicker
            v-model="date"
            :placeholder="t('date.placeholder')"
            date-label=""
          />
          <UiKitFormFieldErrors :errors="v$.modelValue.date.$errors" />
        </UiKitFormField>
      </div>
    </div>
  </div>
</template>

<script>
import RatingStars from '@/components/productManagement/product/content/items/reviews/RatingStars.vue'
import { required } from '@/helpers/i18n-validators'
import UiKitDatePicker from '@/ui/UiKitDatePicker.vue'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitInput from '@/ui/UiKitInput.vue'
import UiKitUploadAny from '@/ui/UiKitUploadAny.vue'
import UiKitWysiwyg from '@/ui/UiKitWysiwyg.vue'
import { useVuelidate } from '@vuelidate/core'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormEditProgram',

  components: {
    UiKitFormFieldErrors,
    UiKitUploadAny,
    UiKitDatePicker,
    UiKitFormField,
    UiKitInput,
    UiKitWysiwyg,
    RatingStars,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      modelValue: {
        authorName: {
          required,
        },

        date: {
          required,
        },

        rate: {
          required,
        },

        subtitle: {
          required,
        },

        text: {
          required,
        },
      },
    }
  },

  computed: {
    photo: {
      get() {
        return this.modelValue.photo
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'photo',
          value,
        })
      },
    },

    authorName: {
      get() {
        return this.modelValue.authorName
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'authorName',
          value,
        })
      },
    },

    subtitle: {
      get() {
        return this.modelValue.subtitle
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'subtitle',
          value,
        })
      },
    },

    date: {
      get() {
        return this.modelValue.date
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'date',
          value,
        })
      },
    },

    text: {
      get() {
        return this.modelValue.text
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'text',
          value,
        })
      },
    },

    rate: {
      get() {
        return this.modelValue.rate
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'rate',
          value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.form.${name}`,
      )
    },
  },
})
</script>
