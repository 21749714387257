import '@/helpers/domains'
import 'tippy.js/animations/shift-toward.css'
import 'tippy.js/dist/tippy.css'
import 'vue-toastification/dist/index.css'

import App from '@/App.vue'
import { RouterAdmin } from '@/RouterAdmin'
import { StoreAdmin } from '@/StoreAdmin'
import AdminAccessControlRules from '@/accessControl/rules'
import SentryHelper from '@/helpers/SentryHelper'
import { i18n } from '@/i18n/setup'
import { registerPluginEcharts } from '@/plugins/echarts'
import { registerPluginSlickSort } from '@/plugins/slicksort'
import { registerPluginToast } from '@/plugins/toast'
import { registerPluginUnhead } from '@/plugins/unhead'
import { initPubSub } from '@/pubsub/subscriber'
import { abilitiesPlugin } from '@casl/vue'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VuePaginatePlugin from '@svifty7/vue-paginate'
import PortalVue from 'portal-vue'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import { screens } from 'vue-screen-utils'
import VueTippy from 'vue-tippy'

initPubSub()

const init = async function () {
  const router = RouterAdmin.init()
  const store = StoreAdmin.init()

  const app = createApp(App)
  const vfm = createVfm()

  app.use(abilitiesPlugin, AdminAccessControlRules.ability, {
    useGlobalProperties: true,
  })
  app.use(PortalVue)
  app.use(store)
  app.use(router)
  app.use(i18n)
  app.use(VueTippy, {
    defaultProps: {
      arrow: true,
      appendTo: () => document.body,
    },
    onShow(options) {
      return Boolean(options.props.content)
    },
  })
  app.use(vfm)

  app.use(screens, {
    xs: '0px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
  })

  app.use(VuePaginatePlugin)

  app.component(VueCountdown.name, VueCountdown)

  registerPluginToast(app)
  registerPluginEcharts(app)
  registerPluginUnhead(app)
  registerPluginSlickSort(app)

  SentryHelper.init(app, router)

  return app
}

/**
 * Обработка ошибки отсутвующего чанка
 * https://vitejs.dev/guide/build.html#load-error-handling
 */
const processViteChunkError = () => {
  window.addEventListener('vite:preloadError', (event) => {
    // eslint-disable-next-line no-console
    console.log('processViteChunkError', event)

    window.location.reload()
  })
}

if (process.iblsEnv.IS_DEVELOPMENT) {
  // eslint-disable-next-line no-console
  console.log(process.iblsEnv)
}

init().then((app) => {
  document.querySelector('#preload')?.remove()
  app.mount('#app')

  processViteChunkError()
})
