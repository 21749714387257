<template>
  <BlockEdit>
    <div class="d-flex align-items-center no-wrap">
      <div v-handle class="p-3 handle cursor-move">
        <i class="fas fa-bars"></i>
      </div>

      <RatingStars :model-value="item.rate" disabled class="mr-2" />
      <div class="text-truncate mr-2">{{ item.authorName }}</div>
    </div>

    <div class="d-flex">
      <UiKitButton class="square" @click="$emit('edit')">
        <i class="fas fa-pen"></i>
      </UiKitButton>

      <UiKitButton class="square" @click="$emit('remove')">
        <i class="fas fa-times"></i>
      </UiKitButton>
    </div>
  </BlockEdit>
</template>

<script>
import BlockEdit from '@/components/productManagement/product/common/BlockEdit.vue'
import RatingStars from '@/components/productManagement/product/content/items/reviews/RatingStars.vue'
import UiKitButton from '@/ui/UiKitButton.vue'
import { defineComponent } from 'vue'
import { HandleDirective } from 'vue-slicksort'

export default defineComponent({
  name: 'ReviewItem',

  directives: {
    handle: HandleDirective,
  },

  components: {
    BlockEdit,
    UiKitButton,
    RatingStars,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'edit',
    'remove',
  ],
})
</script>
