import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setWiki(state, payload) {
    state.wiki = payload
  },

  setTheWiki(state, payload) {
    state.theWiki = payload
  },

  setOption(state, { key, value }) {
    state.option[key] = value
  },
}
