export default {
  resetOptions(state) {
    state.options = {
      schools: [],
      grades: [],
      streams: [],
      educationTypes: [],
      students: [],
    }
  },

  resetStudentAssignmentData(state) {
    state.studentAssignmentData = {
      school: null,
      grade: null,
      stream: null,
      educationType: null,
      students: [],
    }
  },

  resetStreamAssignmentData(state) {
    state.streamAssignmentData = {
      school: null,
      grade: null,
      stream: null,
      educationType: null,
      students: [],
    }
  },

  setOption(state, { key, value }) {
    state.options[key] = value
  },

  setStudentAssignmentDataValue(state, { key, value }) {
    state.studentAssignmentData[key] = value

    // TODO: use rootState somehow instead
    this.state.admin.onlineLesson.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
  },

  setStreamAssignmentDataValue(state, { key, value }) {
    state.streamAssignmentData[key] = value

    // TODO: use rootState somehow instead
    this.state.admin.onlineLesson.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
  },
}
