import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setHomeworks(state, value) {
    state.homeworks = value
  },

  setTheHomework(state, value) {
    state.homework = value
  },

  setHomeworkValue(state, { key, value }) {
    state.homework[key] = value
  },

  setOptions(state, { key, value }) {
    state.options[key] = value
  },

  addAttachment(state, payload = null) {
    if (!state.homework?.managerCommentAttachments) {
      state.homework.managerCommentAttachments = []
    }

    state.homework.managerCommentAttachments = [
      ...state.homework.managerCommentAttachments,
      payload,
    ]
  },

  setAttachment(state, { index, file }) {
    state.homework.managerCommentAttachments[index] = file
  },

  removeAttachment(state, index) {
    state.homework.managerCommentAttachments.splice(index, 1)
  },
}
