<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditReview
        v-if="hasReview"
        :model-value="review"
        @update:model-value="onInput"
      />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditReview from '@/components/productManagement/product/content/items/reviews/FormEditReview.vue'
import { checkForErrorsMessage } from '@/helpers/ValidationHelper'
import { showToast } from '@/helpers/toast'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep, isNull } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalEditProgram',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditReview,
  },

  emits: [
    'change',
    'hide',
  ],

  setup() {
    return {
      v$: useVuelidate({
        $stopPropagation: true,
      }),
    }
  },

  data() {
    return {
      review: null,
    }
  },

  computed: {
    hasReview() {
      return !isNull(this.review)
    },
  },

  methods: {
    async onClickBtnSave() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.$emit('change', this.review)
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.review = cloneDeep(value)
      this.$refs.modal.show()
    },

    hide() {
      this.emitHide()
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
      this.emitHide()
    },

    emitHide() {
      this.$emit('hide')
    },

    resetData() {
      this.v$.$reset()
    },

    onInput({ key, value }) {
      this.review[key] = value
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.modal_edit.${name}`,
      )
    },
  },
})
</script>
