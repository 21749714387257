import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  resetPromocodeGroupValue(state, payload) {
    RESET_STATE(state.promocodeGroup, () => payload)
  },

  setPromocodeGroups(state, payload) {
    state.promocodeGroups = payload
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setPartners(state, payload) {
    state.partners = payload
  },

  setPromocodeGroup(state, payload) {
    state.promocodeGroup = payload
  },

  setPromocodeGroupId(state, payload) {
    state.promocodeGroupId = payload
  },

  setPromocodeGroupValue(state, { key, value }) {
    state.promocodeGroup[key] = value
  },

  setOnlineSchoolSettings(state, { key, value }) {
    state.promocodeGroup.onlineSchoolSettings[key] = value
  },

  setDiscountTypes(state, payload) {
    state.discountTypes = payload
  },

  setPromocodeGroupTypesValue(state, payload) {
    state.promocodeGroupTypes = payload
  },

  setPromocodeGroupUniquePromocode(state, payload) {
    state.isPromocodeExists = payload
  },

  setServices(state, payload) {
    state.services = payload
  },

  setPaymentPeriods(state, payload) {
    state.paymentPeriods = payload
  },

  setSubjectsCount(state, payload) {
    state.subjectsCount = payload
  },
}
