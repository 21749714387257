import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOption(state, { path, value }) {
    state.option[path] = value
  },

  setTopicValue(state, { key, value }) {
    state.theTopic[key] = value
  },

  setQuestionValue(state, { key, value }) {
    state.theQuestion[key] = value
  },

  setChangeTopicWithItems(state, payload) {
    state.topicsWithItems = payload
  },

  setTopicWithItems(state, payload) {
    state.topicsWithItems = payload
  },

  setTheTopic(state, payload) {
    state.theTopic = payload
  },

  setTheQuestion(state, payload) {
    state.theQuestion = payload
  },
}
