const routes = [
  {
    path: 'analytics',
    name: 'admin-analytics',
    component: () => import('@/pages/analytics/Analytics.vue'),
  },

  {
    path: 'analytics/student-enrolled',
    name: 'admin-analytics-student-enrolled',
    component: () => import('@/pages/analytics/AnalyticsStudentEnrolled.vue'),
  },

  {
    path: 'analytics/ctp',
    name: 'admin-analytics-ctp',
    component: () => import('@/pages/analytics/AnalyticsCtp.vue'),
  },

  {
    path: 'analytics/library',
    name: 'admin-analytics-library',
    component: () => import('@/pages/analytics/AnalyticsLibrary.vue'),
  },

  {
    path: 'analytics/online-lessons',
    name: 'admin-analytics-online-lessons',
    meta: {
      accessControlGroup: 'analytics-online-lessons',
    },
    component: () => import('@/pages/analytics/AnalyticsOnlineLessons.vue'),
  },
]

export default routes
