<template>
  <ImaskInput
    v-if="isMasked"
    v-bind="$attrs"
    ref="input"
    v-model:value="value"
    class="form-control"
    :disabled="disabled"
    :mask="mask"
    :placeholder="placeholder"
    :required="required"
    :type="type"
    @blur="onBlur"
  />

  <input
    v-else
    v-bind="$attrs"
    ref="input"
    v-model="value"
    class="form-control"
    :disabled="disabled"
    :placeholder="placeholder"
    :required="required"
    :type="type"
    @blur="onBlur"
    @focus="onFocus"
  />
</template>

<script>
import { isNull } from 'lodash'
import { defineComponent } from 'vue'
import { IMaskComponent } from 'vue-imask'

export default defineComponent({
  name: 'UiKitInput',

  components: {
    ImaskInput: IMaskComponent,
  },

  inheritAttrs: false,

  props: {
    mask: {
      type: null,
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    modelValue: {
      type: [
        String,
        Number,
      ],

      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    focus: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
    'blur',
    'focus',
  ],

  computed: {
    value: {
      get() {
        return isNull(this.modelValue) ? '' : String(this.modelValue)
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    isMasked() {
      return this.mask !== null
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      if (this.focus) {
        await this.$nextTick()
        this.$refs.input.focus()
      }
    },

    onBlur(event) {
      this.$emit('blur', event.target.value)
    },

    onFocus() {
      this.$emit('focus')
    },
  },
})
</script>
