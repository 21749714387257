<template>
  <div class="uiKit-wysiwyg">
    <Editor
      v-if="isEditorAttachedToDom"
      v-bind="$attrs"
      ref="editor"
      v-model="content"
      :init="settings"
      :class="$style.editor"
      @before-exec-command="onEditorBeforeExecCommand"
      @exec-command="onEditorExecCommand"
      @init="onInit"
    />
  </div>
</template>

<script setup>
// import { getCookie } from '@/helpers/cookie'
import Editor from '@tinymce/tinymce-vue'
import ApiUpload from 'Api/Upload'
import { computed, defineComponent, ref } from 'vue'
// import { useStore } from 'vuex'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tinymce from 'tinymce'

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default'

/// * Required TinyMCE components */
import 'tinymce/themes/silver'

/// * Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.min.css'
/// * Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/preview'

import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/visualblocks'

import 'tinymce/plugins/help'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/table'

/* content UI CSS is required */
// import contentUiSkinCssDark from 'tinymce/skins/ui/oxide-dark/skin.css?url'
// import contentUiSkinCss from 'tinymce/skins/ui/oxide/skin.css?url'
/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCssDark from 'tinymce/skins/content/dark/content.css?url'
// import contentCss from 'tinymce/skins/content/default/content.css?url'

defineComponent({
  name: 'UiKitWysiwyg',
  inheritAttrs: false,
})

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },

  modelValue: {
    type: String,
    default: '',
  },

  minHeight: {
    type: Number,
    default: 300,
  },

  plugins: {
    type: [
      Array,
      String,
    ],
    default: () => [
      'advlist autolink lists link image charmap preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table help',
    ],
  },

  toolbar: {
    type: String,
    default: `undo redo | formatselect | bold italic backcolor underline subscript superscript |
                alignleft aligncenter alignright alignjustify |
                bullist numlist outdent indent hr link | image media code table | removeformat | help`,
  },

  toolbarMode: {
    type: String,
    default: 'sliding',
  },

  imagesUploadHandler: {
    type: Function,
    /**
     * @param {Object} blobInfo
     * @param {Function} success
     * @returns {Promise}
     */
    async default(blobInfo, success) {
      const { data } = await ApiUpload.sendImage(blobInfo.blob())

      const imageUrl = data.url

      success(imageUrl)
    },
  },

  setup: {
    type: Function,
    default() {},
  },

  keepStyles: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits([
  'update:modelValue',
  'before-command',
  'command',
  'init',
])

// const store = useStore()

// const isDarkTheme = computed(() => store.state.admin.theme.isDarkTheme)

const editor = ref()

const contentStyle = `.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
                color: rgba(118, 118, 118, 1);
                opacity: 1;
              }`

const api = ref()

defineExpose({
  api,
})

// const cssTheme = computed(() => ({
//  contentCss: isDarkTheme.value ? contentCssDark : contentCss,
//  skin: isDarkTheme.value ? 'oxide-dark' : 'oxide',
//  skinUrl: isDarkTheme.value ? contentUiSkinCssDark : contentUiSkinCss,
// }))

// const language = computed(() => getCookie('lang') || 'ru')

const settings = computed(() => ({
  branding: false,
  // content_css: cssTheme.value.contentCss, // FIXME
  content_style: contentStyle,
  elementpath: false,
  image_dimensions: false,
  images_upload_handler: props.imagesUploadHandler,
  keep_styles: props.keepStyles,
  // language: language.value,
  menubar: false,
  min_height: props.minHeight,
  placeholder: props.placeholder,
  plugins: props.plugins,
  setup: props.setup,
  // skin: cssTheme.value.skin, // FIXME
  // skin_url: cssTheme.value.skinUrl, // FIXME
  toolbar: props.toolbar,
  toolbar_mode: props.toolbarMode,
}))

// Нужен для смены темы
const isEditorAttachedToDom = ref(true)

const content = computed({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  },
})

/**
 *
 * @param {Object} payload
 */
function onEditorBeforeExecCommand(payload) {
  emit('before-command', payload)
}

/**
 * @param {Object} event
 * @param {Object} editor
 */
// eslint-disable-next-line no-shadow
function onEditorExecCommand(event, editor) {
  emit('command', event, editor)
}

/**
 * @param {Object} event
 * @param {Object} editor
 */
// eslint-disable-next-line no-shadow
function onInit(event, editor) {
  emit('init', event, editor)
  api.value = editor
}

// watch(
//  () => isDarkTheme.value,
//  async () => {
//    await setIsEditorAttachedToDom()
//  },
// )
</script>

<style lang="scss">
.uiKit-wysiwyg {
  .tox-tinymce {
    width: 100%;
    border-width: 0;
    border-radius: 5px;
  }
}
</style>

<style lang="scss" module>
.editor {
  display: none;
}
</style>
