<template>
  <div class="d-flex align-items-center mt-2 mb-4">
    <div class="w-100 d-flex flex-column">
      <UiKitFormField class="mb-4" :label="t('name.label')" required>
        <UiKitInput v-model="name" :placeholder="t('name.placeholder')" />

        <UiKitFormFieldErrors :errors="v$.modelValue.name.$errors" />
      </UiKitFormField>

      <UiKitFormField :label="t('description.label')" required>
        <UiKitWysiwyg
          v-model="description"
          :placeholder="t('description.placeholder')"
        />

        <UiKitFormFieldErrors :errors="v$.modelValue.description.$errors" />
      </UiKitFormField>
    </div>
  </div>
</template>

<script>
import { required } from '@/helpers/i18n-validators'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitInput from '@/ui/UiKitInput.vue'
import UiKitWysiwyg from '@/ui/UiKitWysiwyg.vue'
import { useVuelidate } from '@vuelidate/core'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormEditProgram',

  components: {
    UiKitFormFieldErrors,
    UiKitFormField,
    UiKitInput,
    UiKitWysiwyg,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      modelValue: {
        name: {
          required,
        },

        description: {
          required,
        },
      },
    }
  },

  computed: {
    name: {
      get() {
        return this.modelValue.name
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'name',
          value,
        })
      },
    },

    description: {
      get() {
        return this.modelValue.description
      },

      set(value) {
        this.$emit('update:modelValue', {
          key: 'description',
          value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.program.form.${name}`,
      )
    },
  },
})
</script>
