import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPaymentServices(state, payload) {
    state.services = payload
  },

  setTheService(state, payload) {
    state.theService = payload
  },

  setTheServiceValue(state, { key, value }) {
    state.theService[key] = value
  },
}
