import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setQuestionsCounter(state, { path, value }) {
    state.questionCounters[path] = value
  },
}
