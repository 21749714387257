<template>
  <section class="vue-preview" @keydown="onKey">
    <section class="list" @click="onImageClick">
      <span v-for="(img, index) in imageList" :key="index" class="list__img">
        <template v-if="mode === 'image'">
          <img
            :src="img.thumbnailSrc"
            :width="img.width"
            :height="img.height"
            :data-index="index"
            :style="componentOptions.thumbnailStyle"
          />
        </template>

        <template v-else>
          <span class="link" name="img" :data-index="index">{{
            img.name
          }}</span>
        </template>
      </span>
    </section>

    <div v-if="~currentIndex && isShowPre" class="pre-container">
      <header class="pre__header">
        <div>{{ currentIndex + 1 }}/{{ imageList.length }}</div>
        <section :class="$style.iconWrapper">
          <i
            v-if="!inIframe"
            class="fas fa-expand fa-fw"
            @click="toggleFullscreen"
          ></i>

          <i class="fas fa-search-plus fa-fw" @click="zoomin"></i>

          <i class="fas fa-search-minus fa-fw" @click="zoomout"></i>

          <i class="fas fa-sync-alt fa-fw" @click="rotate"></i>

          <i class="fas fa-times fa-fw" @click="close"></i>
        </section>
      </header>

      <div class="pre__content" @click.prevent.stop="close">
        <i
          v-if="currentIndex"
          class="fas fa-chevron-left"
          @click.prevent.stop="prevClick"
        ></i>

        <i
          v-if="currentIndex < imageList.length - 1"
          class="fas fa-chevron-right"
          @click.prevent.stop="nextClick"
        ></i>

        <span
          ref="selectImg"
          :style="{
            cursor: cursorStyle,
            transform: `scale(${scaleRate}) rotate(${rotateRate}deg)`,
          }"
          @click.prevent.stop="imageClick"
        >
          <img
            ref="img"
            :src="imageList[currentIndex].src"
            @load="onImageLoad"
          />
        </span>
      </div>

      <div v-if="showFooter" class="pre__footer">
        <slot name="footer" :image="imageList[currentIndex]">
          <div class="footer">{{ imageList[currentIndex].name }}</div>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
const defaultOptions = {
  defaultWidth: '100px',
  defaultHeight: '75px',
  thumbnailStyle: {
    backgroundSize: 'cover',
  },
  keyMap: {
    zoomin: '+',
    zoomout: '-',
    rotate: 'r',
    prev: 'ArrowLeft',
    next: 'ArrowRight',
  },
}

export default {
  name: 'VuePreviewer',

  props: {
    images: {
      type: Array,
      default: () => [],
    },

    mode: {
      type: String,
      default: 'image',
    },

    options: {
      type: Object,
      default: () => ({}),
    },

    showFooter: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'select',
    'close',
  ],

  data() {
    return {
      inIframe: window.self !== window.top,
      currentIndex: -1,
      isShowPre: false,
      // operation
      scaleRate: 1,
      rotateRate: 0,
      cursorStyle: 'zoom-in',
    }
  },

  computed: {
    imageList() {
      return this.normalizeImage(this.images, {
        sWidth: this.componentOptions.defaultWidth,
        sHeight: this.componentOptions.defaultHeight,
      })
    },

    componentOptions() {
      return {
        ...defaultOptions,
        ...this.options,
      }
    },
  },

  mounted() {
    document.addEventListener('keydown', this.onKey)
  },

  unmounted() {
    document.removeEventListener('keydown', this.onKey)
  },

  methods: {
    onKey(key) {
      const { keyMap = {} } = this.componentOptions

      switch (key.key) {
        case keyMap.prev:
          this.prevClick()
          break
        case keyMap.next:
          this.nextClick()
          break
        case keyMap.zoomin:
          this.zoomin()
          break
        case keyMap.zoomout:
          this.zoomout()
          break
        case keyMap.rotate:
          this.rotate()
          break

        default:
      }
    },

    onImageLoad() {
      const scrHeight = document.documentElement.clientHeight
      const scrWidth = document.documentElement.clientWidth
      const { img } = this.$refs

      if (img) {
        const { width, height } = img.getBoundingClientRect()
        const dW = width / scrWidth
        const dH = height / scrHeight

        if (dW > 1 && dH > 1) {
          if (dW > dH) {
            img.style.width = `${scrWidth}px`
          } else {
            img.style.height = `${scrHeight}px`
          }
        } else if (dW > 1) {
          img.style.width = `${scrWidth}px`
        } else if (dH > 1) {
          img.style.height = `${scrHeight}px`
        }
      }
    },

    normalizeImage(imgs, { sWidth, sHeight }) {
      return imgs.map((img, idx) => {
        // only src source
        if (typeof img === 'string') {
          return {
            width: sWidth,
            height: sHeight,
            src: img,
            thumbnailSrc: img,
            name: `Image ${idx + 1}`,
          }
        }

        return {
          width: img.width || sWidth,
          height: img.height || sHeight,
          src: img.src,
          thumbnailSrc: img.thumbnailSrc || img.src,
          name: img.name,
        }
      })
    },

    async onImageClick({ target }) {
      if (target.nodeName === 'IMG' || target.getAttribute('name') === 'img') {
        this.currentIndex = Number(target.dataset.index)
        this.isShowPre = true
        await this.$nextTick()
        this.$refs.selectImg.focus()
        this.$emit('select', this.imageList[this.currentIndex])
      }
    },

    zoomin() {
      this.scaleRate += 0.5
    },

    zoomout() {
      this.scaleRate -= 0.25
      this.scaleRate = Math.max(this.scaleRate, 0.25)
    },

    rotate() {
      this.rotateRate += 90
    },

    toggleFullscreen() {
      const isFullscreen =
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullscreenElement ||
        document.webkitFullscreenElement ||
        false

      if (isFullscreen) {
        this.exitFullscreen()
      } else {
        this.enterFullscreen()
      }
    },

    exitFullscreen() {
      const el = document.documentElement
      const rfs =
        el.cancelFullscreen ||
        el.webkitExitFullscreen ||
        el.mozCancelFullscreen ||
        el.exitFullscreen

      if (rfs) {
        rfs.call(el)
      } else {
        // eslint-disable-next-line
        console.error("Your browser seems doesn't support fullscreen")
      }
    },

    enterFullscreen() {
      const el = document.documentElement
      const rfs =
        el.requestFullscreen ||
        el.webkitRequestFullscreen ||
        el.mozRequestFullscreen ||
        el.msRequestFullscreen

      if (rfs) {
        rfs.call(el)
      } else {
        // eslint-disable-next-line
        console.error("Your browser seems doesn't support fullscreen")
      }
    },

    imageClick() {
      if (this.cursorStyle === 'zoom-in') {
        this.cursorStyle = 'zoom-out'
        this.scaleRate += 0.5
      } else {
        this.cursorStyle = 'zoom-in'
        this.scaleRate -= 0.5
      }
    },

    prevClick() {
      const selectImgClassList = (this.$refs.selectImg || {}).classList

      if (this.currentIndex > 0) {
        selectImgClassList && selectImgClassList.add('select-img')
        this.currentIndex -= 1
        this.resetRate()
        setTimeout(() => {
          selectImgClassList && selectImgClassList.remove('select-img')
        })
      }
    },

    nextClick() {
      const selectImgClassList = (this.$refs.selectImg || {}).classList

      if (this.currentIndex < this.imageList.length - 1) {
        selectImgClassList && selectImgClassList.add('select-img')
        this.currentIndex += 1
        this.resetRate()
        setTimeout(() => {
          selectImgClassList && selectImgClassList.remove('select-img')
        })
      }
    },

    close() {
      this.currentIndex = -1
      this.isShowPre = false
      this.resetRate()
      this.$emit('close')
    },

    resetRate() {
      this.scaleRate = 1
      this.rotateRate = 0
    },
  },
}
</script>

<style scoped>
.link {
  margin-right: 8px;
  color: #20a0ff;
  cursor: pointer;
}

.pre-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
  background-color: #0e0e0e;
}

.pre__header {
  position: fixed;
  top: 0;
  z-index: 1010;
  display: flex;
  width: calc(100% - 16px);
  max-height: 10%;
  padding: 8px;
  font-size: 16px;
  color: rgb(255 255 255 / 70%);
  justify-content: space-between;
}

.pre__footer {
  position: fixed;
  bottom: 0;
  z-index: 1010;
  display: flex;
  width: 100%;
  max-height: 10%;
  padding: 8px;
  color: white;
  background-color: rgb(0 0 0 / 30%);
  justify-content: center;
}

.pre__content {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

* {
  transition: all 0.3s ease;
}

.select-img {
  transition: rotate 0s ease;
}

.pre__content::-webkit-scrollbar {
  display: none;
}

.prev,
.next {
  position: fixed;
  z-index: 1010;
  padding: 16px;
  font-size: 16px;
  color: rgb(255 255 255 / 70%);
  cursor: pointer;
  border-radius: 50%;
}

.prev:hover,
.next:hover {
  /* stylelint-disable */
  color: darken(white, 10);
  /* stylelint-enable */
  background-color: rgb(255 255 255 / 50%);
}

.prev {
  left: 16px;
}

.next {
  right: 16px;
}

.list__img {
  margin: 8px 4px;
  cursor: pointer;
}
</style>

<style lang="scss" module>
.iconWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  padding: 15px 0;
}
</style>
