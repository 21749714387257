import { RESET_STATE } from '@/helpers/ResetState'
import storage from '@/helpers/storage'
import { flatMapDeep } from 'lodash'
import createState from './state'

/**
 * @param {Object} navItem
 * @returns {Array | object}
 */
const getNavItems = (navItem) => {
  if (!navItem.items?.length) {
    return navItem
  }

  return [
    navItem,
    flatMapDeep(navItem.items, getNavItems),
  ]
}

/**
 * @param {Array} nav
 * @returns {Array}
 */
const getFlatNav = (nav) => flatMapDeep(nav, getNavItems)

/**
 * @param {Array} nav
 * @param {string} id
 * @returns {Object | undefined}
 */
const findNavItemById = (nav, id) =>
  getFlatNav(nav).find((navItem) => navItem.id === id)

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProfile(state, { firstName, lastName, middleName, id, email }) {
    state.profile.firstName = firstName
    state.profile.lastName = lastName
    state.profile.middleName = middleName
    state.profile.id = id
    state.profile.email = email
  },

  setTextsLoaded(state, payload) {
    state.textsLoaded = payload
  },

  setNavigation(state, payload) {
    state.navigation = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.target
   * @param {number} payload.count
   */
  setNotifications(state, { target, count }) {
    const navItem = findNavItemById(state.navigation, target)

    if (navItem) {
      navItem.notifications = count

      // TODO костыль для обновления бокового меню
      state.navigation = [
        ...state.navigation,
      ]
    }
  },

  setActiveModules(state, payload) {
    state.activeModules = payload
  },

  setNavBarState(state, payload = true) {
    storage.set('adminSavedNavBarState', payload)

    state.isBarActive = payload
  },
}
