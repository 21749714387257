<template>
  <div class="mb-4">
    <UiKitFormField :label="t('documents.label')" class="mb-2" required>
      <FileUploadMany v-model="documents" class="mb-4" @error="onErrorUpload" />
      <UiKitFormFieldErrors :errors="v$.localItem.documents.$errors" />
    </UiKitFormField>

    <UiKitFormField :label="t('text.label')" required>
      <UiKitInput
        v-model="documentsText"
        :placeholder="t('text.placeholder')"
      />
      <UiKitFormFieldErrors :errors="v$.localItem.documentsText.$errors" />
    </UiKitFormField>
  </div>
</template>

<script>
import FileUploadMany from '@/components/FileUploadMany.vue'
import { required } from '@/helpers/i18n-validators'
import { NotifyMixin } from '@/helpers/mixins/NotifyMixin'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitInput from '@/ui/UiKitInput.vue'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemDocuments',

  components: {
    UiKitFormFieldErrors,
    FileUploadMany,
    UiKitFormField,
    UiKitInput,
  },

  mixins: [
    NotifyMixin,
  ],

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      localItem: {
        documents: {
          required,
        },

        documentsText: {
          required,
        },
      },
    }
  },

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    documents: {
      get() {
        return this.localItem.documents
      },

      set(value) {
        this.localItem.documents = value

        this.emitChange()
      },
    },

    documentsText: {
      get() {
        return this.localItem.documentsText
      },

      set(value) {
        this.localItem.documentsText = value

        this.emitChange()
      },
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.localItem = cloneDeep(this.modelValue)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', cloneDeep(this.localItem))
    },

    onErrorUpload(message) {
      this.notifyMethods().showError(`${this.t('load_file')}: ${message}`)
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.documents.${name}`)
    },
  },
})
</script>
