import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheIdea(state, payload) {
    state.theIdea = payload
  },

  setTheIdeaValue(state, { path, value }) {
    state.theIdea[path] = value
  },

  setOption(state, { path, value }) {
    state.options[path] = value
  },

  setLogs(state, payload) {
    state.logs = payload
  },
}
