<template>
  <span
    v-if="hasItems"
    :class="{ active: isVisible }"
    class="header"
    @click="processClick"
  >
    <span v-if="props.item.icon" class="uiKit-navigation-icon">
      <span :class="props.item.icon"></span>
    </span>

    <span class="uiKit-navigation-label">
      <router-link
        v-if="props.item.routeName"
        ref="linkRef"
        :to="{ name: props.item.routeName }"
      >
        {{ $t(props.item.text) }}
      </router-link>

      <span v-else>{{ $t(props.item.text) }}</span>
    </span>

    <span v-if="!isVisible" class="uiKit-navigation-arrow">
      <i class="fas fa-caret-down iconArrowDown"></i>
    </span>

    <span v-else class="uiKit-navigation-arrow">
      <i class="fas fa-caret-up iconArrowUp"></i>
    </span>

    <span v-if="props.item.notifications">
      <span :class="$style.counter">{{ props.item.notifications }}</span>
    </span>
  </span>

  <router-link v-else :to="{ name: props.item.routeName }" class="header">
    <span v-if="props.item.icon" class="uiKit-navigation-icon">
      <span :class="props.item.icon"></span>
    </span>

    <span class="uiKit-navigation-label">{{ $t(props.item.text) }}</span>

    <span v-if="props.item.notifications">
      <span :class="$style.counter">{{ props.item.notifications }}</span>
    </span>

    <component :is="appendComponent" v-if="appendComponent" />
  </router-link>
</template>

<script setup>
import { computed, nextTick, ref, toRaw, watch } from 'vue'

defineOptions({
  name: 'UiKitNavigationGroupHeader',
})

const props = defineProps({
  /**
   * Current group visibility
   */
  visible: {
    type: Boolean,
    required: true,
  },

  item: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'toggle',
])

const clonedVisible = ref({})

const linkRef = ref(null)

const isVisible = computed(() => clonedVisible.value)

const hasItems = computed(() => props.item.items?.length > 0)

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const appendComponent = toRaw(props.item.append?.component)

watch(
  () => props.visible,
  () => {
    clonedVisible.value = props.visible
  },
  {
    deep: true,
    immediate: true,
  },
)

/**
 * Show group
 */
function show() {
  clonedVisible.value = true
}

/**
 * Hide group
 */
function hide() {
  clonedVisible.value = false
}

/**
 * Toggle group visibility
 */
function toggle() {
  if (!isVisible.value) {
    show()
  } else {
    hide()
  }
}

/**
 * Toggle group visibility on header click
 * @param {Event} e
 */
async function processClick(e) {
  await nextTick()

  if (e.target !== linkRef.value?.$el) {
    toggle()
    emit('toggle', clonedVisible.value)
  }
}
</script>

<style lang="scss">
@import '~@/scss/functions';
@import '~@/scss/bootstrap/variables';

$componentSelector: '.uiKit-navigation';

#{$componentSelector} {
  &-group {
    > .header {
      position: relative;
      display: flex;
      line-height: 120%;
      color: var(--uiKit-navigation-headerColorInactive);
      text-decoration: inherit;
      cursor: pointer;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;

      @include font-size($fontSize);

      &:hover {
        color: $gray-100;
      }

      #{$componentSelector}-icon {
        width: 20px + 15px;
        color: var(--uiKit-navigation-iconColorInactive);
      }

      &.active #{$componentSelector}-label {
        color: var(--uiKit-navigation-labelColorActive);
      }

      &.active #{$componentSelector}-icon {
        color: $blue;
      }
    }

    #{$componentSelector}-label {
      display: inline-block;
      width: 100%;

      a {
        color: inherit;
        text-decoration: inherit;
      }
    }

    #{$componentSelector}-arrow {
      font-size: 10px;

      .iconArrowDown {
        color: var(--uiKit-navigation-iconColorInactive);
      }

      .iconArrowUp {
        color: $blue;
      }
    }
  }
}
</style>

<style lang="scss" module>
@import '~@/scss/bootstrap/variables';

.counter {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: var(--ui-uiKitNavigation-notifyColor);
  background-color: $pink;
  border-radius: 50%;
}
</style>
