<template>
  <li class="d-flex flex-wrap align-items-center mb-2">
    <template v-if="!isShowingImages">
      <template v-if="props.file.isImage">
        <div class="position-relative">
          <img
            :alt="props.file.name"
            :src="downloadUrl"
            class="upload-many__img mb-2"
          />

          <div :class="$style.imageActions">
            <div
              v-tippy
              content="Редактировать"
              :class="$style.imageAction"
              class="btn btn-secondary"
              @click="onClickBtnEdit"
            >
              <i class="fas fa-pencil-alt"></i>
            </div>

            <div
              v-tippy
              :content="t(`common_components.file_upload_many.rotate_image_to`)"
              :class="$style.imageAction"
              class="btn btn-secondary"
              @click="onClickBtnRotateImage"
            >
              <i class="fas fa-sync"></i>
            </div>
          </div>
        </div>

        <div class="w-100"></div>
      </template>

      <i
        v-else-if="props.file.mime.includes('image')"
        class="fas fa-file-image upload-many__img-icon mr-2"
      ></i>
    </template>

    <span :class="downloadBtnClass" class="btn cursor-default px-0">
      <span class="px-3 p-2">
        {{ fileName }}
      </span>

      <span
        v-if="isRendered.btnDownload"
        class="p-2 cursor-pointer text-secondary border-left"
        @click="onClickBtnDownload"
      >
        <i class="fas fa-download fa-fw"></i>
      </span>

      <span
        v-if="isRendered.btnRemove"
        class="p-2 cursor-pointer text-secondary border-left"
        @click="onClickBtnRemove"
      >
        <i class="fas fa-times fa-fw"></i>
      </span>
    </span>
  </li>
</template>

<script setup>
import ApiDownload from '@/api/Download'
import { ApiFileImageRotate } from '@/api/rest/Api/File/Image/Rotate'
import ModalConfirmRemoveFileUploadMany from '@/components/ModalConfirmRemoveFileUploadMany.vue'
import ModalEditFileUpload from '@/components/ModalEditFileUpload.vue'
import { computed, ref } from 'vue'
import { useModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'

const ROTATE_ANGLE = 90

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },

  isShowingImages: {
    type: Boolean,
    default: false,
  },

  isDownloadable: {
    type: Boolean,
    default: true,
  },

  isConfirmRemove: {
    type: Boolean,
    default: false,
  },

  isClearable: {
    type: Boolean,
    default: true,
  },

  readonly: {
    type: Boolean,
    default: false,
  },

  customDownloadUrl: {
    type: Function,
    default: null,
  },

  downloadBtnClass: {
    type: String,
    default: 'btn-light text-secondary text-truncate',
  },
})

const emit = defineEmits([
  'add-comment-attachment',
  'remove',
  'rotate',
])

const { t } = useI18n() // use as global scope

const originalUrl = ref()

/**
 *
 */
function emitRemove() {
  emit('remove', props.file)
}

/**
 *
 */
function onAddCommentAttachment() {
  emit('add-comment-attachment', props.file)
}

/**
 *
 * @param {Object} file
 */
function emitRotate(file) {
  emit('rotate', file)
}

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const modalRemove = useModal({
  component: ModalConfirmRemoveFileUploadMany,

  attrs: {
    file: props.file,

    onCancel() {
      modalRemove.close()
    },

    onConfirm() {
      emitRemove()
    },
  },
})

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const modalEditFileUpload = useModal({
  component: ModalEditFileUpload,

  attrs: {
    file: props.file,
    originalUrl,
    onUploadFile() {
      onAddCommentAttachment()
    },
  },
})

const isRendered = computed(() => ({
  btnDownload: props.isDownloadable,
  btnRemove: props.isClearable && !props.readonly,
}))

const downloadUrl = computed(() => {
  // Необходимо добавлять "хвост" для того,
  // чтобы браузер не кешировал изображения во время манипуляций с ними,
  // например, при повороте изображения.
  const hash = Date.now()

  if (props.customDownloadUrl) {
    return `${props.customDownloadUrl(props.file)}?${hash}`
  }

  return `${ApiDownload.getFileUrl(props.file.id)}?${hash}`
})

const fileName = computed(
  () => props.file?.name || t(`common_components.file_upload_many.untitled`),
)

/**
 *
 */
function getBase64Image() {
  const image = new Image()

  image.crossOrigin = 'use-credentials'
  image.src = downloadUrl.value

  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.height = props.file.height
      canvas.width = props.file.width
      context.drawImage(image, 0, 0)
      const dataURL = canvas.toDataURL(props.file.mime)

      dataURL.replace('data:', '').replace(/^.+,/, '')

      resolve(dataURL)
    })
  })
}

/**
 *
 */
async function setOriginalUrl() {
  originalUrl.value = await getBase64Image()
}

/**
 *
 */
async function onClickBtnEdit() {
  await setOriginalUrl()
  modalEditFileUpload.open()
}

/**
 *
 */
async function onClickBtnRotateImage() {
  const { data } = await ApiFileImageRotate.patch(props.file.id, ROTATE_ANGLE)

  emitRotate(data)
}

/**
 *
 */
async function onClickBtnRemove() {
  if (!props.isConfirmRemove) {
    modalRemove.open()
  } else {
    emitRemove()
  }
}

/**
 *
 */
function onClickBtnDownload() {
  window.location.href = downloadUrl.value
}
</script>

<style lang="scss" module>
.imageActions {
  $gutter: 20px;

  position: absolute;
  right: $gutter;
  bottom: $gutter;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}

.imageAction {
  width: 40px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
