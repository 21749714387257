const routes = [
  {
    path: '/special-statement',
    name: 'admin-special-statement',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () => import('@/pages/specialStatement/SpecialStatements.vue'),
  },
  {
    path: '/special-statement/create',
    name: 'admin-the-special-statement-create',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import('@/pages/specialStatement/TheSpecialStatementDetail.vue'),
  },
  {
    path: '/special-statement/edit/:specialStatementId',
    name: 'admin-the-special-statement-edit',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () =>
      import('@/pages/specialStatement/TheSpecialStatementDetail.vue'),
  },
  {
    path: '/special-statement/:specialStatementId(\\d+)',
    name: 'admin-the-special-statement',
    meta: {
      accessControlGroup: 'special-statement',
    },
    component: () => import('@/pages/specialStatement/TheSpecialStatement.vue'),
  },
]

export default routes
