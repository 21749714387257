<template>
  <LoaderFullScreen v-if="isLoading" />

  <template v-else>
    <Theme />

    <router-view v-if="isTextsLoaded" />
  </template>

  <ModalsContainer />
</template>

<script>
import LoaderFullScreen from '@/components/LoaderFullScreen.vue'
import Theme from '@/components/Theme.vue'
import { initTrackers } from '@/helpers/trackers/init'
import { ModalsContainer } from 'vue-final-modal'

export default defineComponent({
  name: 'App',

  components: {
    Theme,
    LoaderFullScreen,
    ModalsContainer,
  },

  head() {
    return {
      titleTemplate: (title) => (title ? `${title} | IBLS` : 'IBLS'),
    }
  },

  computed: {
    ...mapState({
      isLoadingTheme: (state) => state.admin.theme.isLoading,
    }),

    ...mapState('admin/navigation', [
      'textsLoaded',
    ]),

    isLoading() {
      return this.isLoadingTheme
    },

    isTextsLoaded() {
      return this.textsLoaded
    },
  },

  created() {
    this.init()
  },

  methods: {
    ...mapActions('admin/theme', [
      'getTheme',
    ]),

    ...mapActions('admin/navigation', [
      'fetchProfile',
    ]),

    async init() {
      await Promise.all([
        this.getTheme(),
        this.fetchProfile(),
        initTrackers(),
      ])
    },
  },
})
</script>

<style lang="scss">
@import '~@/app';
</style>
