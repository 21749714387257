<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <div class="d-flex align-items-center mt-2 mb-4">
        <div class="w-100 d-flex flex-column">
          <UiKitFormField class="mb-4" :label="t('image.label')" required>
            <UiKitUploadAny v-model="item" />

            <UiKitFormFieldErrors :errors="v$.item.$errors" />
          </UiKitFormField>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import { checkForErrorsMessage } from '@/helpers/ValidationHelper'
import { required } from '@/helpers/i18n-validators'
import { showToast } from '@/helpers/toast'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import UiKitUploadAny from '@/ui/UiKitUploadAny.vue'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalAddGalleryItem',

  components: {
    UiKitFormFieldErrors,
    UiKitFormField,
    UiKitUploadAny,
    UiKitModal,
    UiKitButton,
  },

  emits: [
    'change',
  ],

  setup() {
    return {
      v$: useVuelidate({
        $stopPropagation: true,
      }),
    }
  },

  validations() {
    return {
      item: {
        required,
      },
    }
  },

  data() {
    return {
      item: null,
    }
  },

  methods: {
    async onClickBtnSave() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.$emit('change', this.item)
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.item = cloneDeep(value)
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.item = null
      this.v$.$reset()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.gallery.modal_edit.${name}`,
      )
    },
  },
})
</script>
