import { ApiOnlineLessonRoom } from 'ApiRest/Api/OnlineLesson/Room'
import { ApiOnlineLessonRoomList } from 'ApiRest/Api/OnlineLesson/RoomList'

export default {
  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @param {Object} filters
   * @returns {Promise}
   */
  async fetchOnlineLessonRooms({ commit }, filters = {}) {
    const { data, headers } = await ApiOnlineLessonRoomList.get(filters)

    commit('setOnlineLessonRooms', data)
    commit('setOnlineLessonRoomsPagination', headers)
  },

  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @param {number} roomId
   * @returns {Promise}
   */
  async fetchTheOnlineLessonRoom({ commit }, roomId) {
    const { data } = await ApiOnlineLessonRoom.get(roomId)

    commit('setTheOnlineLessonRoom', data)
  },

  /**
   * @param {Object} args
   * @param {Object} args.state
   * @returns {Promise}
   */
  async createTheOnlineLessonRoom({ state }) {
    await ApiOnlineLessonRoom.post(state.theRoom)
  },

  /**
   * @param {Object} args
   * @param {Object} args.state
   * @param {number} roomId
   * @returns {Promise}
   */
  async updateTheOnlineLessonRoom({ state }, roomId) {
    await ApiOnlineLessonRoom.patch(roomId, state.theRoom)
  },

  /**
   * @param {Object} args
   * @param {Function} args.commit
   * @param {number} roomId
   * @returns {Promise}
   */
  async deleteTheOnlineLessonRoom({ commit }, roomId) {
    await ApiOnlineLessonRoom.delete(roomId)

    commit('resetState')
  },
}
