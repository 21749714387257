<template>
  <UiKitModal2 :name="name">
    <div class="p-4">
      <!-- TODO: i18n -->
      <h5 class="modal-title mb-4">Внимание</h5>

      <div>
        <!-- TODO: i18n -->
        <div class="mb-4">
          Вы действительно хотите удалить файл <b>{{ fileName }}</b
          >?
        </div>

        <div class="d-flex justify-content-lg-end justify-content-between">
          <!-- TODO: i18n -->
          <UiKitButton
            class="btn-danger btn-lg"
            label="Да, удалить"
            @click="onClickBtnRemoveFile"
          />

          <!-- TODO: i18n -->
          <UiKitButton
            class="btn-secondary btn-lg ml-4"
            label="Отмена"
            @click="onClickBtnCloseModal"
          />
        </div>
      </div>
    </div>
  </UiKitModal2>
</template>

<script>
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalConfirmRemoveFileUploadMany',

  components: {
    UiKitButton,
    UiKitModal2,
  },

  props: {
    file: {
      type: Object,
      default: () => null,
    },
  },

  emits: [
    'cancel',
    'confirm',
  ],

  data() {
    return {
      name: Symbol('ModalConfirmRemoveFileUploadMany'),
    }
  },

  computed: {
    fileName() {
      return this.file?.name ?? null
    },
  },

  methods: {
    onClickBtnCloseModal() {
      this.$emit('cancel')
    },

    onClickBtnRemoveFile() {
      this.$emit('confirm')
    },
  },
})
</script>
