<template>
  <UiKitModal2 :name="name" width="80%" height="80vh" title="Редактирование">
    <ContentWithPreloader :is-loading="isLoading">
      <ImageEditor
        :image="file"
        :original-url="originalUrl"
        @save-edited-image="onSaveEditedImage"
      />
    </ContentWithPreloader>
  </UiKitModal2>
</template>

<script>
import ImageEditor from '@/components/ImageEditor.vue'
import { showToast } from '@/helpers/toast'
import ContentWithPreloader from '@/ui/ContentWithPreloader.vue'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import ApiUpload from 'Api/Upload'
import { uniqueId } from 'lodash'
import { defineComponent } from 'vue'
import { useVfm } from 'vue-final-modal'

export default defineComponent({
  name: 'ModalEditFileUpload',

  components: {
    ImageEditor,
    UiKitModal2,
    ContentWithPreloader,
  },

  props: {
    file: {
      type: Object,
      default: null,
    },

    originalUrl: {
      type: String,
      default: null,
    },

    isPrivate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'upload-file',
  ],

  setup() {
    const vfm = useVfm()

    return {
      vfm,
    }
  },

  data() {
    return {
      name: uniqueId('ModalEditFileUpload'),
    }
  },

  computed: {
    originalFileId() {
      if (this.file.originalFile === 'undefined') {
        return this.file.id
      }

      return this.file.originalFile?.id ?? null
    },

    isLoading() {
      return !this.originalUrl
    },
  },

  methods: {
    /**
     * @public
     */
    show() {
      this.vfm.open(this.name)
    },

    hide() {
      this.vfm.close(this.name)
    },

    onSaveEditedImage(file) {
      return ApiUpload.sendFile(
        file,
        () => {},
        this.isPrivate,
        this.originalFileId,
      ).then(({ data }) => {
        this.$emit('upload-file', data)
        this.hide()

        return showToast('Файл добавлен в поле с ответом')
      })
    },
  },
})
</script>
