import { set } from 'lodash'

export default {
  setOption(state, { key, value }) {
    state.options[key] = value
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setSettings(state, payload) {
    state.settings = payload
  },

  setSettingValue(state, { path, value }) {
    set(state.settings, path, value)
  },

  setSettingContract(state, { path, value }) {
    set(state.settings.contract, path, value)
  },

  setSettingOffer(state, { path, value }) {
    set(state.settings.offer, path, value)
  },
}
