import ProductContentItemDocuments from '@/components/productManagement/product/content/items/ProductContentItemDocuments.vue'
import ProductContentItemGallery from '@/components/productManagement/product/content/items/ProductContentItemGallery.vue'
import ProductContentItemLeaders from '@/components/productManagement/product/content/items/ProductContentItemLeaders.vue'
import ProductContentItemPartners from '@/components/productManagement/product/content/items/ProductContentItemPartners.vue'
import ProductContentItemProgram from '@/components/productManagement/product/content/items/ProductContentItemProgram.vue'
import ProductContentItemQuestions from '@/components/productManagement/product/content/items/ProductContentItemQuestions.vue'
import ProductContentItemReviews from '@/components/productManagement/product/content/items/ProductContentItemReviews.vue'
import ProductContentItemSimilarProducts from '@/components/productManagement/product/content/items/ProductContentItemSimilarProducts.vue'
import ProductContentItemTags from '@/components/productManagement/product/content/items/ProductContentItemTags.vue'
import ProductContentItemText from '@/components/productManagement/product/content/items/ProductContentItemText.vue'
import { i18n } from '@/i18n/setup'
import Constants from 'Constants'

const createCommonFields = (typeId) => ({
  id: null,
  type: {
    id: typeId,
  },
  name: '',
})

const createTextItem = () => ({
  text: {
    body: '',
    isBackgroundTransparent: false,
  },
})

const createProgramItem = () => ({
  program: [],
})

const createLeadersItem = () => ({
  leaders: [],
})

const createReviewsItem = () => ({
  reviews: [],
})

const createGalleryItem = () => ({
  gallery: [],
})

const createPartnersItem = () => ({
  partners: [],
})

const createQuestionsItem = () => ({
  privacyFile: null,
})

const createDocumentsItem = () => ({
  documents: [],
  documentsText: '',
})

const createSimilarProductsItem = () => ({
  products: [],
})

const createTagsItem = () => ({
  tags: [],
})

const itemMapper = {
  [Constants.productContentType.TEXT]: createTextItem,
  [Constants.productContentType.PROGRAM]: createProgramItem,
  [Constants.productContentType.LEADERS]: createLeadersItem,
  [Constants.productContentType.REVIEWS]: createReviewsItem,
  [Constants.productContentType.GALLERY]: createGalleryItem,
  [Constants.productContentType.PARTNERS]: createPartnersItem,
  [Constants.productContentType.QUESTIONS]: createQuestionsItem,
  [Constants.productContentType.DOCUMENTS]: createDocumentsItem,
  [Constants.productContentType.SIMILAR_PRODUCTS]: createSimilarProductsItem,
  [Constants.productContentType.TAGS]: createTagsItem,
}

export const createContentsOptions = () => [
  {
    id: Constants.productContentType.TEXT,
    name: i18n.global.t('helpers.content_options.text'),
  },
  {
    id: Constants.productContentType.PROGRAM,
    name: i18n.global.t('helpers.content_options.program'),
  },
  {
    id: Constants.productContentType.LEADERS,
    name: i18n.global.t('helpers.content_options.leaders'),
  },
  {
    id: Constants.productContentType.REVIEWS,
    name: i18n.global.t('helpers.content_options.reviews'),
  },
  {
    id: Constants.productContentType.GALLERY,
    name: i18n.global.t('helpers.content_options.gallery'),
  },
  {
    id: Constants.productContentType.PARTNERS,
    name: i18n.global.t('helpers.content_options.partners'),
  },
  {
    id: Constants.productContentType.QUESTIONS,
    name: i18n.global.t('helpers.content_options.questions'),
  },
  {
    id: Constants.productContentType.DOCUMENTS,
    name: i18n.global.t('helpers.content_options.documents'),
  },
  {
    id: Constants.productContentType.SIMILAR_PRODUCTS,
    name: i18n.global.t('helpers.content_options.similar_products'),
  },
  {
    id: Constants.productContentType.TAGS,
    name: i18n.global.t('helpers.content_options.tags'),
  },
]

export const itemComponentMapper = {
  [Constants.productContentType.TEXT]: ProductContentItemText,
  [Constants.productContentType.PROGRAM]: ProductContentItemProgram,
  [Constants.productContentType.LEADERS]: ProductContentItemLeaders,
  [Constants.productContentType.REVIEWS]: ProductContentItemReviews,
  [Constants.productContentType.GALLERY]: ProductContentItemGallery,
  [Constants.productContentType.PARTNERS]: ProductContentItemPartners,
  [Constants.productContentType.QUESTIONS]: ProductContentItemQuestions,
  [Constants.productContentType.DOCUMENTS]: ProductContentItemDocuments,
  [Constants.productContentType.SIMILAR_PRODUCTS]:
    ProductContentItemSimilarProducts,
  [Constants.productContentType.TAGS]: ProductContentItemTags,
}

export const createProductContentItem = (typeId) => ({
  ...createCommonFields(typeId),
  ...itemMapper[typeId](),
})
