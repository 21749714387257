import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOptions(state, { key, value }) {
    state.options[key] = value
  },

  setFamilyCertificationsSchedule(state, payload) {
    state.certificationsSchedule = payload
  },

  setFamilyCertificationSchedule(state, payload) {
    state.certificationSchedule = payload
  },

  setFamilyCertificationScheduleValue(state, { key, value }) {
    state.certificationSchedule[key] = value
  },
}
