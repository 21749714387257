export default {
  setOptionsList(state, { path, value }) {
    state.options[path] = value
  },

  setClientId(state, id) {
    state.clientId = id
  },

  setOrders(state, data) {
    state.orders = data
  },

  setInvoices(state, data) {
    state.invoices = data
  },
}
