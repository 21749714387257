<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditReview :model-value="review" @update:model-value="onInput" />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_add')"
          @click="onClickBtnAdd"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditReview from '@/components/productManagement/product/content/items/reviews/FormEditReview.vue'
import { checkForErrorsMessage } from '@/helpers/ValidationHelper'
import { showToast } from '@/helpers/toast'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { defineComponent } from 'vue'

const createReview = () => ({
  photo: null,
  rate: 0,
  authorName: '',
  subtitle: '',
  text: '',
  date: null,
})

export default defineComponent({
  name: 'ModalAddReview',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditReview,
  },

  emits: [
    'add',
  ],

  setup() {
    return {
      v$: useVuelidate({
        $stopPropagation: true,
      }),
    }
  },

  data() {
    return {
      review: createReview(),
    }
  },

  methods: {
    async onClickBtnAdd() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.$emit('add', this.review)

      this.review = createReview()
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.review = createReview()
      this.v$.$reset()
    },

    onInput({ key, value }) {
      this.review[key] = value
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.reviews.modal_add.${name}`,
      )
    },
  },
})
</script>
