<script>
import { formatToMb } from '@/helpers/formatToMb'
import { showToast } from '@/helpers/toast'
import ApiDownload from 'Api/Download'
import ApiUpload from 'Api/Upload'
import UploadException from 'Api/exceptions/Upload'
import heic2any from 'heic2any'
import { cloneDeep } from 'lodash'

export default {
  name: 'Uploader',

  props: {
    /**
     * @typedef {Object} File
     * @property {number} id
     * @property {string} name
     * @property {string} mime
     */

    /**
     * @typedef {Object} FileImage
     * @property {string} url
     * @property {string} thumbUrl
     */

    /**
     * @returns {FileImage|null}
     */
    image: {
      type: Object,
      default: null,
    },

    /**
     * @returns {File|null}
     */
    file: {
      type: Object,
      default: null,
    },

    mimeAllowed: {
      type: Array,
      default: () => [],
    },

    isVideo: {
      type: Boolean,
      default: false,
    },

    isDocument: {
      type: Boolean,
      default: false,
    },

    maxFileSizeMb: {
      type: Number,
      default: 50,
    },
  },

  emits: [
    'clear-image',
    'clear-file',
    'error',
    'upload-image',
    'upload-file',
  ],

  data() {
    return {
      localImage: {
        id: this.image?.id,
        thumbUrl: this.image?.thumbUrl,
        url: this.image?.url,
        mime: this.image?.mime,
        name: this.image?.name,
      },

      localFile: {
        id: this.file?.id,
        mime: this.file?.mime,
        name: this.file?.name,
        isPrivate: this.file?.isPrivate,
        isImage: this.file?.isImage,
        thumbUrl: this.file?.thumbUrl,
      },

      dragover: false,
      progress: 0,
    }
  },

  computed: {
    /**
     * @returns {boolean}
     */
    hasDragAndDropSupport() {
      const $div = document.createElement('div')

      return (
        ('draggable' in $div || ('ondragstart' in $div && 'ondrop' in $div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },

    /**
     * @returns {boolean}
     */
    isImageUploaded() {
      return !!this.localImage?.id
    },

    /**
     * @returns {string | null}
     */
    imageUrl() {
      return this.localImage?.url ?? null
    },

    /**
     * @returns {string | null}
     */
    imageThumbUrl() {
      return this.localImage?.thumbUrl ?? null
    },

    /**
     * @returns {string | null}
     */
    imageName() {
      return this.localImage?.name ?? null
    },

    /**
     * @returns {boolean}
     */
    isFileUploaded() {
      return !!this.localFile?.id
    },

    /**
     * @returns {string | null}
     */
    fileName() {
      return this.localFile?.name ?? null
    },

    /**
     * @returns {string | null}
     */
    thumbUrl() {
      return this.localFile?.thumbUrl ?? null
    },

    /**
     * @returns {string | null}
     */
    downloadUrl() {
      return ApiDownload.getFileUrl(this.localFile?.id)
    },

    /**
     * @returns {string | null}
     */
    fileMime() {
      return this.localFile?.mime ?? null
    },

    /**
     * @returns {boolean}
     */
    isImage() {
      return !!this.localFile?.isImage
    },
  },

  watch: {
    image: {
      handler() {
        this.localImage = cloneDeep(this.image)
      },

      deep: true,
    },

    file: {
      handler() {
        this.localFile = cloneDeep(this.file)
      },

      deep: true,
    },
  },

  methods: {
    drag() {
      return {
        over: () => {
          this.dragover = true
        },

        leave: () => {
          this.dragover = false
        },
      }
    },

    /**
     * Get target element and chosen file
     * @param {Event|{dataTransfer}} e
     * @param {string | null} mime - Allowed mime type (or its prefix)
     * @returns {{HTMLInputElement, File}}
     * @throws {UploadException}
     * @private
     */
    getTargetAndFile(e, mime = null) {
      let $target
      let file

      if (e.dataTransfer) {
        if (e.dataTransfer.items) {
          const item = e.dataTransfer.items[0]

          if (!item || item.kind !== 'file') {
            throw new UploadException('Некорректный тип файла')
          }

          file = item.getAsFile()
        } else {
          file = e.dataTransfer.files ? e.dataTransfer.files[0] : null
        }
      } else {
        $target = e.target

        if (!$target && !this.hasDragAndDropSupport) {
          throw new UploadException('Не удалось выбрать файл')
        }

        file = $target.files ? $target.files[0] : null
      }

      if (!file) {
        throw new UploadException('Файл не выбран')
      }

      if (mime && file.type.substr(0, mime.length) !== mime) {
        throw new UploadException('Тип файла запрещен для загрузки')
      }

      if (this.mimeAllowed?.length > 0) {
        const hasMimeAllowed =
          this.mimeAllowed.filter((mimeAllowedItem) =>
            file.type.includes(mimeAllowedItem),
          ).length > 0

        if (!hasMimeAllowed) {
          throw new UploadException('Данный тип файла не поддерживается')
        }
      }

      return {
        $target,
        file,
      }
    },

    /**
     * @param {HTMLInputElement} $target
     * @private
     */
    clearTarget($target) {
      if ($target) {
        $target.value = ''
      }
    },

    /**
     * @private
     * @returns {Object}
     */
    emit() {
      return {
        /**
         * @param {string} message
         * @returns {boolean}
         */
        error: (message) => {
          this.$emit('error', message)

          return false
        },

        upload: () => ({
          image: () => {
            this.$emit('upload-image', cloneDeep(this.localImage))
          },

          file: () => {
            this.$emit('upload-file', cloneDeep(this.localFile))
          },
        }),

        clear: () => ({
          image: () => {
            this.$emit('clear-image')
          },

          file: () => {
            this.$emit('clear-file')
          },
        }),
      }
    },

    /**
     * @private
     * @param {Object} error
     * @returns {boolean}
     */
    processError(error) {
      return error.name === 'upload' ? this.emit().error(error.message) : true
    },

    /**
     * @param {Event|{loaded,total}} e
     */
    _onUploadProgress(e) {
      this.progress = Math.round((e.loaded * 100) / e.total)
    },

    /**
     * @private
     * @param {Event|{dataTransfer}} e
     * @returns {void|*|boolean}
     */
    async processUploadDocument(e) {
      e.preventDefault()
      e.stopPropagation()

      this.drag().leave()

      try {
        const { $target, file } = this.getTargetAndFile(e)

        if (this.isFileOverSize(formatToMb(file.size), this.maxFileSizeMb)) {
          this.progress = 0

          return showToast(
            this.$t('common_components.uploader.max_file_size_error', {
              maxFileSizeMb: this.maxFileSizeMb,
            }),
            `error`,
          )
        }

        await ApiUpload.sendDocument(file, this._onUploadProgress)
          .then(({ data }) => {
            // Reset upload progress
            this.progress = 0

            // Set new file data
            this.localFile = data

            // Clear target source
            this.clearTarget($target)

            this.emit().upload().file()
          })
          .catch((error) => {
            // Reset upload progress

            this.progress = 0

            // Clear target source

            this.clearTarget($target)

            // Process error

            return this.processError(error)
          })
      } catch (error) {
        // Reset upload progress

        this.progress = 0

        // Process error

        return this.processError(error)
      }

      return true
    },

    /**
     * @param {Event|{dataTransfer}} e
     * @returns {boolean|void}
     */
    async processUploadImage(e) {
      e.preventDefault()
      e.stopPropagation()

      this.drag().leave()

      try {
        const { $target, file } = this.getTargetAndFile(e, 'image')

        if (this.isFileOverSize(formatToMb(file.size), this.maxFileSizeMb)) {
          this.progress = 0

          return showToast(
            this.$t('common_components.uploader.max_file_size_error', {
              maxFileSizeMb: this.maxFileSizeMb,
            }),
            `error`,
          )
        }

        await ApiUpload.sendImage(file, this._onUploadProgress)
          .then(({ data }) => {
            // Reset upload progress

            this.progress = 0

            // Set new image data

            this.localImage = {
              id: data.id,
              url: data.url,
              thumbUrl: data.thumbUrl,
              mime: data.mime,
              name: file.name,
            }

            // Clear target source

            this.clearTarget($target)

            this.emit().upload().image()
          })
          .catch((error) => {
            // Reset upload progress

            this.progress = 0

            // Clear target source

            this.clearTarget($target)

            // Process error

            return this.processError(error)
          })
      } catch (error) {
        // Reset upload progress

        this.progress = 0

        // Process error

        return this.processError(error)
      }

      return true
    },

    async processUploadVideo(e, mime = null) {
      e.preventDefault()
      e.stopPropagation()

      this.drag().leave()

      try {
        const { $target, file } = this.getTargetAndFile(e, mime)

        if (this.isFileOverSize(formatToMb(file.size), this.maxFileSizeMb)) {
          this.progress = 0

          return showToast(
            this.$t('common_components.uploader.max_file_size_error', {
              maxFileSizeMb: this.maxFileSizeMb,
            }),
            `error`,
          )
        }

        await ApiUpload.sendVideo(file, this._onUploadProgress)
          .then(({ data }) => {
            // Reset upload progress
            this.progress = 0

            // Set new file data
            this.localFile = data

            // Clear target source
            this.clearTarget($target)

            this.emit().upload().file()
          })
          .catch((error) => {
            // Reset upload progress

            this.progress = 0

            // Clear target source

            this.clearTarget($target)

            // Process error

            return this.processError(error)
          })
      } catch (error) {
        // Reset upload progress

        this.progress = 0

        // Process error

        return this.processError(error)
      }

      return true
    },

    /**
     * @private
     * @param {Event|{dataTransfer}} e
     * @param {string} customUploadUrl
     * @returns {boolean}
     */
    async processUploadFileToCustomEndpoint(e, customUploadUrl) {
      e.preventDefault()
      e.stopPropagation()

      this.drag().leave()

      try {
        const { $target, file } = this.getTargetAndFile(e)

        await ApiUpload.sendFileToCustomEndpoint({
          url: customUploadUrl,
          file,
        })
          .then(({ data }) => {
            // Reset upload progress
            this.progress = 0

            // Set new file data
            this.localFile = data

            // Clear target source
            this.clearTarget($target)

            this.emit().upload().file()
          })
          .catch((error) => {
            // Reset upload progress

            this.progress = 0

            // Clear target source

            this.clearTarget($target)

            // Process error

            return this.processError(error)
          })
      } catch (error) {
        // Reset upload progress

        this.progress = 0

        // Process error

        return this.processError(error)
      }

      return true
    },

    /**
     * @private
     * @param {Event|{dataTransfer}} e
     * @param {string | null} mime - Allowed mime type (or its prefix)
     * @param {boolean} isPrivate
     * @returns {void|*|boolean}
     */
    async processUploadFile(e, mime = null, isPrivate = false) {
      if (this.isVideo) {
        return this.processUploadVideo(e)
      }

      if (this.isDocument) {
        return this.processUploadDocument(e)
      }

      e.preventDefault()
      e.stopPropagation()

      this.drag().leave()

      try {
        const { $target } = this.getTargetAndFile(e, mime)
        let { file } = this.getTargetAndFile(e, mime)

        const fileNameExt = file.name
          .substr(file.name.lastIndexOf('.') + 1)
          .toLowerCase()

        if (fileNameExt === 'heic') {
          file = await this.convertHeicToJpeg(file)
        }

        if (this.isFileOverSize(formatToMb(file.size), this.maxFileSizeMb)) {
          this.progress = 0

          return showToast(
            this.$t('common_components.uploader.max_file_size_error', {
              maxFileSizeMb: this.maxFileSizeMb,
            }),
            `error`,
          )
        }

        await ApiUpload.sendFile(file, this._onUploadProgress, isPrivate)
          .then(({ data }) => {
            // Reset upload progress
            this.progress = 0

            // Set new file data
            this.localFile = data

            // Clear target source
            this.clearTarget($target)

            this.emit().upload().file()
          })
          .catch((error) => {
            // Reset upload progress

            this.progress = 0

            // Clear target source

            this.clearTarget($target)

            // Process error

            return this.processError(error)
          })
      } catch (error) {
        // Reset upload progress

        this.progress = 0

        // Process error

        return this.processError(error)
      }

      return true
    },

    /**
     * @param {Event} e
     * @returns {void|*|boolean}
     */
    processUploadAudio(e) {
      return this.processUploadFile(e, 'audio')
    },

    /**
     * @param {number} fileSize
     * @param {number} maxSize
     * @returns {boolean}
     */
    isFileOverSize(fileSize, maxSize) {
      return fileSize > maxSize
    },

    /**
     * @param {Event} e
     * @param {boolean} isPrivate
     * @returns {void|*|boolean}
     */
    processUploadAny(e, isPrivate = false) {
      return this.processUploadFile(e, null, isPrivate)
    },

    /**
     * @param {Event} e
     * @param {string} customUploadUrl
     * @returns {*|boolean}
     */
    processUploadCustomEndpoint(e, customUploadUrl) {
      return this.processUploadFileToCustomEndpoint(e, customUploadUrl)
    },

    processClearImage() {
      this.localImage = null

      this.emit().upload().image()
      this.emit().clear().image()
    },

    processClearFile() {
      this.localFile = null

      this.emit().upload().file()
      this.emit().clear().file()
    },

    /**
     * @param {HTMLInputElement} $input
     */
    processChange($input) {
      if ($input) {
        $input.click()
      }
    },

    convertHeicToJpeg(file) {
      const name = file.name.slice(0, -4)

      return new Promise((resolve) => {
        heic2any({
          blob: file,
          toType: 'image/jpeg',
        }).then((fileBlob) => {
          resolve(
            new File(
              [
                fileBlob,
              ],
              `${name}jpg`,
              {
                type: 'image/jpeg',
              },
            ),
          )
        })
      })
    },
  },

  render() {
    return this.$slots.default({
      data: {
        dragover: this.dragover,
        fileName: this.fileName,
        hasDragAndDropSupport: this.hasDragAndDropSupport,
        imageName: this.imageName,
        imageThumbUrl: this.imageThumbUrl,
        thumbUrl: this.thumbUrl,
        downloadUrl: this.downloadUrl,
        mime: this.fileMime,
        isImage: this.isImage,
        imageUrl: this.imageUrl,
        isFileUploaded: this.isFileUploaded,
        isImageUploaded: this.isImageUploaded,
        progress: this.progress,
      },

      actions: {
        drag: this.drag,
        processChange: this.processChange,
        processClearFile: this.processClearFile,
        processClearImage: this.processClearImage,
        processUploadAny: this.processUploadAny,
        processUploadCustomEndpoint: this.processUploadCustomEndpoint,
        processUploadAudio: this.processUploadAudio,
        processUploadImage: this.processUploadImage,
      },

      events: {
        drag: {
          dragover: (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.drag().over()
          },

          dragenter: (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.drag().over()
          },

          dragend: (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.drag().leave()
          },

          dragleave: (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.drag().leave()
          },

          drag: (e) => {
            e.preventDefault()
            e.stopPropagation()
          },

          dragstart: (e) => {
            e.preventDefault()
            e.stopPropagation()
          },
        },
      },
    })
  },
}
</script>
