import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheLibraryQuestion(state, payload) {
    state.question = payload
  },

  setTheLibraryQuestionValue(state, { key, value }) {
    state.question[key] = value
  },

  setTheLibraryQuestionContent(state, content) {
    state.question.content = content
  },

  setTheLibraryQuestionMaterials(state, content) {
    state.question.materials = content
  },
}
