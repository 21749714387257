<template>
  <ul class="body" :class="{ hidden: !isVisible }">
    <li
      v-for="(subItem, subItemIndex) in props.item.items"
      :key="subItemIndex"
      class="uiKit-navigation-item"
    >
      <router-link
        v-if="!subItem.items"
        :to="{ name: subItem.routeName }"
        class="uiKit-navigation-link"
      >
        {{ $t(subItem.text) }}
      </router-link>

      <UiKitNavigationGroup
        v-else
        :item="subItem"
        @toggle="($event) => processToggle(subItemIndex, $event)"
      />
    </li>
  </ul>
</template>

<script setup>
import UiKitNavigationGroup from '@/ui/UiKitNavigationGroup.vue'
import { cloneDeep } from 'lodash'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  /**
   * Current item and its children
   */
  item: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'toggle',
])

defineOptions({
  name: 'UiKitNavigationGroupBody',
})

const clonedItem = ref({})

/**
 * Hide all groups
 */
function hideAllGroups() {
  Object.keys(clonedItem.value.items).forEach((groupKey) => {
    clonedItem.value.items[groupKey].visible = false
  })
}

/**
 * Update subgroups visibility
 * @param {number} itemIndex
 * @param {Object} data
 */
function processToggle(itemIndex, data) {
  hideAllGroups()
  clonedItem.value.items[itemIndex] = data
  emit('toggle', cloneDeep(clonedItem.value))
}

const isVisible = computed(() => clonedItem.value.visible)

watch(
  () => props.item,
  () => {
    clonedItem.value = cloneDeep(props.item)
  },
  {
    immediate: true,
    deep: true,
  },
)
</script>

<style lang="scss">
@use 'sass:math';
@import '~@/scss/functions';
@import '~@/scss/bootstrap/variables';

$component-selector: '.uiKit-navigation';

#{$component-selector} {
  &-group {
    > .body {
      padding: 0;
      margin: 0;
      list-style: none;

      &.hidden {
        display: none;
      }
    }

    #{$component-selector}-item {
      // Spacing between items
      padding: 20px 0 0;
    }

    #{$component-selector}-link {
      position: relative;
      display: block;
      padding: 0 0 0 (20px + 15px) + math.div(20px, 1.5);
      line-height: 24px;
      color: var(--uiKit-navigation-headerColorInactive);
      text-decoration: inherit;
      word-break: break-word;

      &::before {
        position: absolute;
        top: 0;
        left: 20px + 15px;
        width: 4px;
        height: 24px;
        content: '';
        background: url('~Static/images/components/uiKit/navigation/bulletInactive.svg')
          center center no-repeat;
      }

      &:hover {
        color: $blue;

        &::before {
          background-image: url('~Static/images/components/uiKit/navigation/bulletActive.svg');
        }
      }
    }
  }
}
</style>
