<template>
  <div class="uiKit-navigation-group">
    <UiKitNavigationGroupHeader
      :visible="visible"
      :item="clonedItem"
      @toggle="processGroupToggle"
    />

    <UiKitNavigationGroupBody
      v-if="hasItems"
      :item="clonedItem"
      @toggle="processSubGroupsToggle"
    />
  </div>
</template>

<script>
import UiKitNavigationGroupBody from '@/ui/UiKitNavigationGroupBody.vue'
import UiKitNavigationGroupHeader from '@/ui/UiKitNavigationGroupHeader.vue'
import { cloneDeep } from 'lodash'

export default {
  name: 'UiKitNavigationGroup',

  components: {
    UiKitNavigationGroupHeader,
    UiKitNavigationGroupBody,
  },

  props: {
    /**
     * Current item and its children
     */
    item: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'toggle',
  ],

  data() {
    return {
      clonedItem: {},
    }
  },

  computed: {
    visible() {
      return this.clonedItem.visible || false
    },

    hasItems() {
      return !!this.clonedItem.items?.length
    },
  },

  watch: {
    item: {
      handler() {
        // Sync local data with a prop
        this.clonedItem = cloneDeep(this.item)
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     * Update current group visibility
     * @param {Event} e
     */
    processGroupToggle(e) {
      this.clonedItem.visible = e
      this.$emit('toggle', cloneDeep(this.clonedItem))
    },

    /**
     * Update sub groups visibility
     * @param {Event} e
     */
    processSubGroupsToggle(e) {
      this.clonedItem = e
      this.$emit('toggle', cloneDeep(this.clonedItem))
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';
@import '~@/scss/bootstrap/variables';

.uiKit-navigation-group {
  // Spacing between groups
  padding: 0 0 20px;

  // Sub groups
  & & {
    padding: 0 0 0 (20px + 15px) + math.div(20px, 1.5);
  }
}

$component-selector: '.uiKit-navigation';

#{$component-selector} {
  &-group {
    > .body {
      padding: 0;
      margin: 0;
      list-style: none;

      &.hidden {
        display: none;
      }
    }

    #{$component-selector}-item {
      // Spacing between items
      padding: 20px 0 0;
    }

    #{$component-selector}-link {
      position: relative;
      display: block;
      padding: 0 0 0 (20px + 15px) + math.div(20px, 1.5);
      line-height: 24px;
      color: var(--uiKit-navigation-headerColorInactive);
      text-decoration: inherit;
      word-break: break-word;

      &::before {
        position: absolute;
        top: 0;
        left: 20px + 15px;
        width: 4px;
        height: 24px;
        content: '';
        background: url('~Static/images/components/uiKit/navigation/bulletInactive.svg')
          center center no-repeat;
      }

      &:hover {
        color: $blue;

        &::before {
          background-image: url('~Static/images/components/uiKit/navigation/bulletActive.svg');
        }
      }
    }
  }
}
</style>
