<script>
import { h } from 'vue'

export default {
  name: 'VirtualStyle',

  render() {
    return h('style', this.$slots.default())
  },
}
</script>
