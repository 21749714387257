import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   *
   * @param {Object} state
   * @param {Object} payload
   */
  setThePartner(state, payload) {
    state.partner = {
      ...state.partner,
      ...payload,
    }
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.key
   * @param {*} payload.value
   */
  setValue(state, { key, value }) {
    state.partner[key] = value
  },

  addCoordinator(state) {
    const nullCoordinator = {
      firstName: null,
      lastName: null,
      middleName: null,
      email: null,
      phone: null,
    }

    state.partner.coordinators = [
      ...state.partner.coordinators,
      nullCoordinator,
    ]
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {number} payload.index
   * @param {Object} payload.value
   */
  updateCoordinator(state, { index, value }) {
    state.partner.coordinators[index] = value
  },

  /**
   *
   * @param {Object} state
   * @param {number} index
   */
  deleteCoordinator(state, index) {
    state.partner.coordinators = state.partner.coordinators.filter(
      (item, itemIndex) => itemIndex !== index,
    )
  },

  /**
   *
   * @param {Object} state
   * @param {Array} payload
   */
  setComments(state, payload) {
    state.comments = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.key
   * @param {Array} payload.data
   */
  setOptions(state, { key, data }) {
    state.options[key] = data
  },
}
