<template>
  <div>
    <UiKitFormField :label="t('label')" class="mb-2">
      <UiKitWysiwyg
        ref="wysiwyg"
        v-model="localItem.text.body"
        :placeholder="t('placeholder')"
        :keep-styles="false"
        :setup="setup"
        :toolbar="toolbar"
        @init="onInit"
        @update:model-value="emitChange"
        @command="onCommand"
      />
    </UiKitFormField>

    <UiKitCheckbox
      v-model="localItem.text.isBackgroundTransparent"
      :label="t('transparent_background')"
      @update:model-value="emitChange"
    />

    <ModalAddElements ref="ModalAddElements" @add="onAddElements" />

    <ModalAddTextsWithTitle
      ref="ModalAddTextsWithTitle"
      @add="onAddTextsWithTitle"
    />

    <ModalAddIcons ref="ModalAddIcons" @add="onAddIcons" />
  </div>
</template>

<script>
import ModalAddElements from '@/components/productManagement/product/content/items/text/ModalAddElements.vue'
import ModalAddIcons from '@/components/productManagement/product/content/items/text/ModalAddIcons.vue'
import ModalAddTextsWithTitle from '@/components/productManagement/product/content/items/text/ModalAddTextsWithTitle.vue'
import UiKitCheckbox from '@/ui/UiKitCheckbox.vue'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitWysiwyg from '@/ui/UiKitWysiwyg.vue'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemText',

  components: {
    ModalAddElements,
    ModalAddTextsWithTitle,
    ModalAddIcons,
    UiKitFormField,
    UiKitCheckbox,
    UiKitWysiwyg,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    api() {
      return this.$refs.wysiwyg?.api ?? null
    },

    toolbar() {
      return `sampleMenu code visualblocks contentPreview | undo redo | formatselect | bold italic backcolor underline subscript superscript |
              alignleft aligncenter alignright alignjustify |
              bullist numlist outdent indent hr link | image media table | removeformat | help`
    },

    sampleMenu() {
      return [
        {
          type: 'menuitem',
          text: 'Элементы',
          onAction: this.onClickAddSampleElements,
        },

        {
          type: 'menuitem',
          text: 'Текст с картинкой',
          onAction: this.onClickAddSampleImageWithText,
        },

        {
          type: 'menuitem',
          text: 'Заголовок и текст',
          onAction: this.onClickAddSampleTextsWithTitle,
        },

        {
          type: 'menuitem',
          text: 'Иконки',
          onAction: this.onClickAddSampleIcons,
        },
      ]
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.localItem = this.modelValue
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('update:modelValue', cloneDeep(this.localItem))
    },

    onCommand({
      command,
      value: { src, alt } = {
        src: '',
        alt: '',
      },
    }) {
      if (command === 'mceUpdateImage') {
        this.addSampleImageWithText(src, alt)
      }
    },

    onClickAddSampleElements() {
      this.showModalAddElements()
    },

    onClickAddSampleImageWithText() {
      this.api.execCommand('mceImage')
    },

    onClickAddSampleTextsWithTitle() {
      this.showModalAddTextsWithTitle()
    },

    onClickAddSampleIcons() {
      this.showModalIcons()
    },

    showModalAddElements() {
      this.$refs.ModalAddElements.show()
    },

    showModalAddTextsWithTitle() {
      this.$refs.ModalAddTextsWithTitle.show()
    },

    showModalIcons() {
      this.$refs.ModalAddIcons.show()
    },

    onAddTextsWithTitle({ columns }) {
      this.addTextsWithTitle(columns)
    },

    onAddElements({ dots, columns }) {
      this.addElements(dots, columns)
    },

    onAddIcons({ icons }) {
      this.addIcons(icons)
    },

    addElements(withDots, columnsCount) {
      const createElementsString = () => {
        let str = ''

        for (let i = 0; i < columnsCount; i++) {
          str += `
            <li class="content-sample-elements__item">
              Элемент
            </li>
          `
        }

        return str
      }

      const str = `
        <ul class="content-sample-elements content-sample-elements-columns-${columnsCount} ${
          withDots ? 'with-dots' : ''
        }">
          ${createElementsString()}
        </ul>
      `

      this.api.insertContent(str)
    },

    addTextsWithTitle(columnsCount) {
      const createElementsString = () => {
        let str = ''

        for (let i = 0; i < columnsCount; i++) {
          str += `
            <div class="content-sample-texts-with-title-item">
              <div class="content-sample-texts-with-title-item__title">Заголовок</div>
              <p class="content-sample-texts-with-title-item__text">Текст</p>
            </div>
          `
        }

        return str
      }

      const str = `
        <div class="content-sample-texts-with-title content-sample-texts-with-title-columns-${columnsCount}">
          ${createElementsString()}
        </div>
      `

      this.api.insertContent(str)
    },

    addIcons(icons) {
      const createElementsString = () =>
        icons.reduce(
          (acc, icon) => `${acc}
          <div class="content-sample-icons-item">
            <div class="content-sample-icons-item__icon">
              <img src="${icon.icon.url}" />
            </div>
            <p class="content-sample-icons-item__text">${icon.text}</p>
          </div>
        `,
          '',
        )

      const str = `
        <div class="content-sample-icons">
          ${createElementsString()}
        </div>
      `

      this.api.insertContent(str)
    },

    addSampleImageWithText(src, alt) {
      const content = `
        <div class="row product-content-sample-text-with-image">
          <div class="col col-md-6 d-flex align-items-center">
            <div class="text">
              Текст
            </div>
          </div>

          <div class="col col-md-6 d-flex align-items-center">
            <div class="text-right">
              <img class="w-100" src='${src}' alt='${alt}'>
            </div>
          </div>
        </div>
        &nbsp;
      `

      this.api.insertContent(content)
    },

    setup(editor) {
      editor.ui.registry.addMenuButton('sampleMenu', {
        text: 'Контент',

        fetch: (callback) => {
          callback(this.sampleMenu)
        },
      })
    },

    onInit({
      target: {
        editorCommands: {
          commands: { exec },
        },
      },
    }) {
      exec.mcevisualblocks()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.text.${name}`)
    },
  },
})
</script>
