<template>
  <div :class="wrapperClass">
    <DatePicker
      v-bind="$attrs"
      v-model.string="localDate"
      is24hr
      :locale="localeLang"
      :is-dark="isDarkTheme"
      :popover="popover"
      :masks="masksLocal"
      :update-on-input="false"
      :columns="columns"
      :mode="mode"
      hide-time-header
      expanded
      @update:model-value="onInput"
    >
      <template #default="{ inputValue, togglePopover }">
        <div v-if="isRange" class="row">
          <div class="col">
            <UiKitFormField
              :label="isStartDateLabel"
              :required="required"
              :errors="errors.startDate"
            >
              <div class="date-picker">
                <div class="input-group">
                  <span
                    class="date-picker__button date-picker__calendar-button input-group-prepend"
                    @click="togglePopover"
                  >
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </span>

                  <input
                    class="form-control"
                    :value="inputValue.start"
                    :placeholder="isStartDatePlaceholder"
                    :disabled="disabled"
                    @change="setDate($event, 'start')"
                    @click="togglePopover"
                  />

                  <span
                    v-if="localDate.start && !disabled"
                    class="date-picker__button date-picker__clear-button input-group-append"
                    @click="resetDate"
                  >
                    <span class="input-group-text">
                      <i class="fa fa-times"></i>
                    </span>
                  </span>
                </div>
              </div>
            </UiKitFormField>
          </div>

          <div class="col">
            <UiKitFormField
              :label="isEndDateLabel"
              :required="required"
              :errors="errors.endDate"
            >
              <div class="date-picker">
                <div class="input-group">
                  <span
                    class="date-picker__button date-picker__calendar-button input-group-prepend"
                    @click="togglePopover"
                  >
                    <span class="input-group-text">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </span>

                  <input
                    class="form-control"
                    :value="inputValue.end"
                    :placeholder="isEndDatePlaceholder"
                    :disabled="disabled"
                    @change="setDate($event, 'end')"
                    @click="togglePopover"
                  />

                  <span
                    v-if="localDate.end && !disabled"
                    class="date-picker__button date-picker__clear-button input-group-append"
                    @click="resetDate"
                  >
                    <span class="input-group-text">
                      <i class="fa fa-times"></i>
                    </span>
                  </span>
                </div>
              </div>
            </UiKitFormField>
          </div>
        </div>

        <UiKitFormField
          v-else
          :label="isDateLabel"
          :required="required"
          :errors="errors.date"
        >
          <div class="date-picker">
            <div class="input-group">
              <span
                class="date-picker__button date-picker__calendar-button input-group-prepend"
                @click="togglePopover"
              >
                <span class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </span>
              </span>

              <input
                class="form-control"
                :value="inputValue"
                :placeholder="isDatePlaceholder"
                :disabled="disabled"
                @change="setDate"
                @click="togglePopover"
              />

              <span
                v-if="localDate && !disabled"
                class="date-picker__button date-picker__clear-button input-group-append"
                @click="resetDate"
              >
                <span class="input-group-text">
                  <i class="fa fa-times"></i>
                </span>
              </span>
            </div>
          </div>
        </UiKitFormField>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { getCookie } from '@/helpers/cookie'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import { isNaN } from 'lodash'
import { DatePicker } from 'v-calendar'
import { mapGetters } from 'vuex'

const DATE_FORMAT_FRONTEND = 'DD.MM.YYYY'
const DATE_TIME_FORMAT_FRONTEND = 'DD.MM.YYYY HH:mm'
const DATE_FORMAT_BACKEND = 'YYYY-MM-DD'
const DATE_TIME_FORMAT_BACKEND = 'YYYY-MM-DD HH:mm:00'
const TIME_FORMAT_BACKEND = 'HH:mm'

export default {
  name: 'UiKitDatePicker',

  components: {
    UiKitFormField,
    DatePicker,
  },

  inheritAttrs: false,

  props: {
    modelValue: {
      type: null,
      required: true,
    },

    popover: {
      type: Object,
      default: () => ({
        visibility: 'click',
      }),
    },

    masks: {
      type: Object,
      default() {
        return null
      },
    },

    mode: {
      type: String,
      default: 'date',
    },

    dateLabel: {
      type: String,
      default: null,
    },

    startDateLabel: {
      type: String,
      default: '',
    },

    endDateLabel: {
      type: String,
      default: '',
    },

    datePlaceholder: {
      type: String,
      default: '',
    },

    startDatePlaceholder: {
      type: String,
      default: '',
    },

    endDatePlaceholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Object,
      default: () => ({}),
    },

    wrapperClass: {
      type: [
        String,
        Array,
      ],

      default: undefined,
    },
  },

  emits: [
    'update:modelValue',
    'reset-date',
  ],

  data() {
    return {
      localDate: null,
    }
  },

  computed: {
    ...mapGetters('admin/theme', [
      'isDarkTheme',
    ]),

    localeLang() {
      return getCookie('lang') || 'ru'
    },

    masksLocal() {
      if (this.masks) {
        return this.masks
      }

      let modelValueMask = DATE_FORMAT_BACKEND

      if (this.mode === 'dateTime') {
        modelValueMask = DATE_TIME_FORMAT_BACKEND
      }

      if (this.mode === 'time') {
        modelValueMask = TIME_FORMAT_BACKEND
      }

      return {
        input: [
          DATE_FORMAT_FRONTEND,
        ],

        inputDateTime24hr: [
          DATE_TIME_FORMAT_FRONTEND,
        ],

        modelValue: modelValueMask,
      }
    },

    isDateLabel() {
      return this.dateLabel ?? this.$t('ui.props_default.date')
    },

    isRange() {
      return this.$attrs['is-range'] !== undefined
    },

    isDateTime() {
      return this.mode === 'dateTime'
    },

    isTime() {
      return this.mode === 'time'
    },

    isStartDateLabel() {
      return this.startDateLabel ?? this.$t('ui.props_default.start_date_label')
    },

    isEndDateLabel() {
      return this.endDateLabel ?? this.$t('ui.props_default.end_date_label')
    },

    isDatePlaceholder() {
      return this.datePlaceholder ?? this.$t('ui.props_default.date_label')
    },

    isStartDatePlaceholder() {
      return (
        this.startDatePlaceholder ??
        this.$t('ui.props_default.start_date_label')
      )
    },

    isEndDatePlaceholder() {
      return this.endDatePlaceholder ?? this.$t('ui.props_default.end_period')
    },

    hasColumns() {
      return this.$attrs.columns !== undefined
    },

    columns() {
      if (this.hasColumns) {
        return this.$attrs.columns
      }

      if (!this.isRange) {
        return 1
      }

      return 2
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.localDate = this.modelValue
      },

      immediate: true,
    },
  },

  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value)
    },

    /*
     * TODO: Костыль в связи с багом парсинга введенной вручную даты в локали, отличной от en-US
     * https://github.com/nathanreyes/v-calendar/issues/792
     * */
    setDate($event, dateValue = '') {
      const value = $event.target.value.trim()

      // Если пустая строка
      if (!value) {
        this.resetDate()

        return true
      }

      let result = ''

      if (this.isTime) {
        const timeDivider = /:/
        const mask = this.masksLocal.modelValue
        const [
          maskHours,
          maskMinutes,
          maskSeconds,
        ] = mask.split(timeDivider)
        let [
          hours = '00',
          minutes = '00',
          seconds = '00',
        ] = value.split(timeDivider)

        hours = hours.padStart(2, '0')
        minutes = minutes.padStart(2, '0')
        seconds = seconds.padStart(2, '0')

        const hoursNumber = Number(hours)
        const minutesNumber = Number(minutes)
        const secondsNumber = Number(seconds)

        if (
          !isNaN(hoursNumber) &&
          !isNaN(minutesNumber) &&
          !isNaN(secondsNumber)
        ) {
          if (hoursNumber < 0 || hoursNumber > 23) {
            hours = '00'
          }

          if (minutesNumber < 0 || minutesNumber > 59) {
            minutes = '00'
          }

          if (secondsNumber < 0 || secondsNumber > 59) {
            seconds = '00'
          }

          const items = []

          if (maskHours !== undefined) {
            items.push(hours)
          }

          if (maskMinutes !== undefined) {
            items.push(minutes)
          }

          if (maskSeconds !== undefined) {
            items.push(seconds)
          }

          result = items.filter(Boolean).join(':')
        }
      } else {
        const [
          date,
          time,
        ] = value.split(' ')
        const dateDivider = /[/.,-]/
        const [
          day = '1',
          month = '1',
          year = '20',
        ] = date.split(dateDivider)

        const dayFormatted = day.padStart(2, '0')
        const monthFormatted = month.padStart(2, '0')
        const yearFormatted = year.padStart(4, '20')

        result = [
          yearFormatted,
          monthFormatted,
          dayFormatted,
        ].join('-')

        if (time) {
          const timeDivider = /:/
          const [
            hour = '00',
            minute = '00',
            second = '00',
          ] = time.split(timeDivider)
          const hourFormatted = hour.padStart(2, '0')
          const minuteFormatted = minute.padStart(2, '0')
          const secondFormatted = second.padStart(2, '0')
          const timeResult = [
            hourFormatted,
            minuteFormatted,
            secondFormatted,
          ].join(':')

          result += ` ${timeResult}`
        } else if (this.isDateTime) {
          result += ` 00:00:00`
        }

        // Если не дата
        if (!Date.parse(result)) {
          return false
        }
      }

      if (this.isRange) {
        this.localDate[dateValue] = result
        Object.keys(this.localDate).forEach((key) => {
          this.localDate[key] ||= result
        })
      } else {
        this.localDate = result
      }

      this.onInput(this.localDate)

      return true
    },

    resetDate() {
      this.localDate = null

      this.onInput(this.localDate)

      this.$emit('reset-date')
    },
  },
}
</script>

<style lang="scss">
@import 'v-calendar/dist/style.css';
</style>

<style module lang="scss">
[class~='vc-date'] {
  display: none !important;
}

[class~='vc-nav-container'] button,
[class~='vc-header'] button {
  color: inherit;
  background-color: transparent;
}
</style>
