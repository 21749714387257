import { RESET_STATE } from '@/helpers/ResetState'
import { weekDays } from '@/helpers/const/weekDays'
import { isArray } from 'lodash'
import createState from './state'

/**
 * @property {number} index - Порядковый номер
 * @property {string} subject - Предмет
 * @property {string} startTime - Время начала
 * @property {string} endTime - Время окончания
 * @property {string} classroom - Аудитория
 */
class Lesson {
  constructor() {
    this.index = null
    this.subject = ''
    this.startTime = ''
    this.endTime = ''
    this.classroom = ''
  }
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setTheTimetable(state, payload) {
    state.timetable = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.key
   * @param {*} payload.value
   */
  setValue(state, { key, value }) {
    state.timetable[key] = value
  },

  /**
   *
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.key
   * @param {*} payload.data
   */
  setOptions(state, { key, data }) {
    state.options[key] = data
  },

  /**
   * @param {Object} state
   * @param {string} day - День недели
   * @returns {Array}
   */
  addLesson(state, day) {
    const lessonTemplate = new Lesson()
    let timetableDay = state.timetable[day]

    if (!isArray(timetableDay)) {
      timetableDay = []
    }

    timetableDay.push(lessonTemplate)

    return timetableDay
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {string} payload.day - День недели
   * @param {number} payload.lessonIndex - Индекс урока
   */
  removeLesson(state, { day, lessonIndex }) {
    state.timetable[day].splice(lessonIndex, 1)
  },

  setLessonValue(state, { lesson, key, value }) {
    lesson[key] = value
  },

  setLessonsIndexes(state) {
    weekDays.forEach(({ id: weekDayId }) => {
      const timeTableDay = state.timetable[weekDayId]

      if (timeTableDay) {
        timeTableDay.forEach((lesson, lessonIndex) => {
          lesson.index = lessonIndex
        })
      }
    })
  },
}
