import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOption(state, { key, value }) {
    state.option[key] = value
  },

  setSourceValue(state, { key, value }) {
    state.source[key] = value
  },

  setTargetValue(state, { key, value }) {
    state.target[key] = value
  },
}
