<template>
  <BlockEdit>
    <div class="d-flex align-items-center">
      <div v-handle class="p-3 handle cursor-move">
        <i class="fas fa-bars"></i>
      </div>

      <div :class="$style.wrapper">
        <img :src="item.thumbUrl" :alt="item.name" />
      </div>
    </div>

    <div class="d-flex">
      <UiKitButton class="square" @click="$emit('edit')">
        <i class="fas fa-pen"></i>
      </UiKitButton>

      <UiKitButton class="square" @click="$emit('remove')">
        <i class="fas fa-times"></i>
      </UiKitButton>
    </div>
  </BlockEdit>
</template>

<script>
import BlockEdit from '@/components/productManagement/product/common/BlockEdit.vue'
import UiKitButton from '@/ui/UiKitButton.vue'
import { defineComponent } from 'vue'
import { HandleDirective } from 'vue-slicksort'

export default defineComponent({
  name: 'PartnersItem',

  directives: {
    handle: HandleDirective,
  },

  components: {
    BlockEdit,
    UiKitButton,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  emits: [
    'edit',
    'remove',
  ],
})
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
}
</style>
