import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import { cloneDeep, omit } from 'lodash'
import createState from './state'

const getTheGradeSubjectSettingsTemplate = (gradeId) => ({
  grade: {
    id: gradeId,
    name: gradeId,
  },
  // TODO: mvp hardcoded
  subjectSettings: [
    {
      subject: {
        id: 1,
        name: 'Русский язык',
      },
      checker: null,
    },
    {
      subject: {
        id: 20,
        name: 'Алгебра',
      },
      checker: null,
    },
    {
      subject: {
        id: 7,
        name: 'Геометрия',
      },
      checker: null,
    },
  ],
})

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setApplications(state, payload) {
    state.applications = payload
  },

  setApplication(state, payload) {
    state.application = payload
  },

  setServices(state, payload) {
    state.services = payload.map((service) =>
      omit(service, [
        'price',
      ]),
    )
  },

  setApplicationStatuses(state, payload) {
    state.applicationStatuses = payload
  },

  setConfiguration(state, payload) {
    state.configuration = payload
  },

  setOptions(state, { key, data }) {
    state.options[key] = data
  },

  setSettingsSubjects(state, payload = []) {
    state.gradeSubjectSettings = payload
  },

  changeGradeSubjectSettings(state, grades) {
    const gradesIds = grades.map((i) => i.id)
    let settings = cloneDeep(state.gradeSubjectSettings)

    settings = settings.filter((setting) =>
      gradesIds.includes(setting.grade.id),
    )
    const settingsGradesIds = settings.map((setting) => setting.grade.id)

    gradesIds.forEach((gradeId) => {
      if (!settingsGradesIds.includes(gradeId)) {
        settings.push(getTheGradeSubjectSettingsTemplate(gradeId))
      }
    })

    state.gradeSubjectSettings = settings
  },

  changeChecker(state, { checker, gradeId, subjectId }) {
    const gradeSetting = state.gradeSubjectSettings.find(
      (setting) => setting.grade.id === gradeId,
    )
    const subjectSetting = gradeSetting.subjectSettings.find(
      (setting) => setting.subject.id === subjectId,
    )

    subjectSetting.checker = checker
  },

  addRetake(state, payload) {
    state.application.retakes.push(payload)
  },

  setManagers(state, managers) {
    state.managers = managers
  },

  setSchoolId(state, schoolId) {
    state.schoolId = schoolId
  },

  setSubjectsExtramuralDiagnostics(state, subjects) {
    state.subjectsExtramuralDiagnostics = subjects
  },

  setSubjectsFullTimeDiagnostics(state, subjects) {
    state.subjectsFullTimeDiagnostics = subjects
  },

  setTests(state, tests) {
    state.tests = tests
  },

  setGrade(state, grade) {
    state.grade = grade
  },

  setSubjects(state, subjects) {
    state.subjects = subjects
  },

  setSubjectsMassStartWork(state, subjects) {
    state.subjectsMassStartWork = subjects
  },

  addSubjects(state, { key, subject }) {
    if (Array.isArray(state[key].subjectSettings)) {
      state[key].subjectSettings.push(subject)
    } else {
      state[key].subjectSettings = [
        subject,
      ]
    }
  },

  removeSubject(state, { key, subjectId }) {
    const { subjectSettings } = state[key]
    const subjectsFilter = subjectSettings.filter(
      (subject) => subject.subject.id !== subjectId,
    )

    state[key].subjectSettings = subjectsFilter
  },

  changeFullTImeDiagnocticsChecker(state, { settingIndex, value }) {
    state.subjectsFullTimeDiagnostics.subjectSettings[settingIndex].checker =
      value
  },

  setApplicationId(state, payload) {
    state.applicationId = payload
  },

  setStatus(state, payload) {
    state.application.status = payload
  },

  setIsStudentEducationalProgramBlocked(state, payload) {
    state.isStudentEducationalProgramBlocked = payload
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const paginationState = state.pagination

    if (paginationState !== null) {
      compositePagination.setRowsPerPage(paginationState.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, filters) {
    state.filters = filters
  },
}
